import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, Slide } from "@mui/material";
import BulkUpload from "./bulkupload.svg";

// Function for transition effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const UploadPopup = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      TransitionComponent={Transition} // Adding the transition effect
    >
      {/* Icon + Title */}
      <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
        <Box display="flex" justifyContent="center" alignItems="center" mb={1}>
          <img alt="logo" src={BulkUpload} style={{ width: "200px", height: "100px" }} />
        </Box>
        Uploaded Successfully
      </DialogTitle>

      {/* Content */}
      <DialogContent>
        <Typography variant="body2" textAlign="center">
          All the employees have been added. <br />
          Please proceed to the Lite application to capture their photos.
        </Typography>
      </DialogContent>

      {/* Close Button */}
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button onClick={onClose} sx={{ backgroundColor: "#0A3B66", color: "white" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadPopup;
