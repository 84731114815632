import React, { useContext, useState } from "react";
import { empContext } from "./myProvider";
import axios from "axios";
export const useEmployee = () => {
  return useContext(empContext);
};
const EmpProvider = ({ children }) => {
  const [employee, setEmployee] = useState([]);
  const [error, setError] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [attendanceFilter, setAttendanceFilter] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [completeLog, setCompleteLog] = useState([]);
  const [logLength, setLogLength] = useState(0);
  const [reportLoading, setReportLoading] = useState(false);
  const [selectedDate,setSelectedDate]=useState({
    startDate:"",
    endDate:""
});
const [commonRightSidebar, setCommonRightSidebar] = useState(false);

  const [employeeDetails, setEmployeeDetails] = useState({
    _id: null,
    customerid: "",
    firstName: "",
    isFaceRegistered: false,
    lastName: "",
    employeeId: "",
    dob: "",
    gender: "",
    emailPersonal: "",
    emailOfficial: "",
    mobileNo: "",
    address: "",
    joiningDate: "",
    department: "",
    designation: "",
    employmentType: "",
    workLocation: "",
    blockOrBuildingNo: "",
    shift: "",
    idProofType: "",
    idProofNo: "",
    idProofPhoto: null,
    isTerminated: false,
    isSuspended: false,
    createdAt: "",
    updatedAt: "",
    profilePhoto: "",
    addressLine1: "",
    addressLine2: "",
    pincode: "",
    city: "",
    isHr: false,

    __v: null,
  });

  const [department, setDepartment] = useState([]);
  const [employment, setEmployment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [workLocation, setWorkLocation] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [building, setBuilding] = useState([]);


  const [loadingMultipleFilter, setLoadingMultipleFilter] = useState(true);
  const [errorMultipleFilter, setErrorMultipleFilter] = useState(null);

  const handleTerminated = async (employeeDetails) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/employees/edit`,
        { isTerminated: true },
        {
          params: { employeeId: employeeDetails?.employeeId },
        }
      );

      if (response.data.success) {
        const empInfo = employee?.map((e, i) => {
          if (e.employeeId === employeeDetails.employeeId) {
            setEmployeeDetails({ ...employeeDetails, isTerminated: true });
            return { ...e, isTerminated: true };
          }
          return e;
        });
        console.log(empInfo);
        setEmployee(empInfo);
      }
      return true;
    } catch (e) {
      // console.log("error during terminated Employee");
      setError("error during terminated Employee");
      return false;
    }
  };

  const handleSuspended = async (employeeDetails, type) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/employees/edit`,
        { isSuspended: type === "Suspend" ? true : false },
        {
          params: { employeeId: employeeDetails?.employeeId },
        }
      );

      if (response.data.success) {
        const empInfo = employee?.map((e, i) => {
          if (e.employeeId === employeeDetails.employeeId) {
            setEmployeeDetails({
              ...employeeDetails,
              isSuspended: type === "Suspend" ? true : false,
            });
            return { ...e, isSuspended: type === "Suspend" ? true : false };
          }
          return e;
        });
        console.log(empInfo);
        setEmployee(empInfo);
      }
      return true;
    } catch (e) {
      // console.log("error during terminated Employee");
      setError("error during terminated Employee");
      return false;
    }
  };
  return (
    <empContext.Provider
      value={{
        employee,
        setError,
        setEmployee,
        handleTerminated,
        handleSuspended,
        employeeDetails,
        setEmployeeDetails,
        isUpdate,
        setIsUpdate,
        attendanceData,
        setAttendanceData,
        attendanceFilter,
        setAttendanceFilter,
        filteredData,
        setFilteredData,
        completeLog,
        setCompleteLog,
        department,
        setDepartment,
        employment,
        shiftList, setShiftList,
        building, setBuilding,
        setEmployment,
        designation,
        setDesignation,
        workLocation,
        setWorkLocation,
        setErrorMultipleFilter,
        setLoadingMultipleFilter,
        logLength,
        setLogLength,
        reportLoading,
        setReportLoading,
        selectedDate,setSelectedDate,
        commonRightSidebar, setCommonRightSidebar
      }}
    >
      {children}
    </empContext.Provider>
  );
};

export default EmpProvider;
