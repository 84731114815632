import React, { useState, useEffect, useCallback } from "react";
import Layout from "../../components/Layout/AdminLayout/Layout";
import employeePic from "../../assets/images/employeeDetials.svg";
import searchIcons from "../../assets/images/searchIcons.svg";
import filterIcons from "../../assets/images/Filter list alt.svg";
import employeePicList from "../../assets/images/employee.svg";
import { useEmployee } from "../../context/EmpProvider";
import { useModel } from "../../hooks/useModel/useModel";
import LogDetails from "../../model/logDetails/LogDetails";
import EditProfile from "../../model/editProfile/EditProfile";
import Confirm from "../../model/confirm/Confirm";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import axios from "axios";
import IdProof from "../../model/idProof/IdProof";
import Loader from "../../assets/gif/Loader.gif";
import InfiniteScroll from "react-infinite-scroll-component";
import { MdCheckBox } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { useRef } from "react";
import search_icons from "../../assets/images/searchIcons.svg";
import EmployeeInfo from "../../skeleton/employeeDetails/EmployeeInfo";
import EmployeeList from "../../skeleton/employeeDetails/EmployeeList";
import NominateHr from "../../model/nominate/NominateHr";
import Nominate from "../../components/employeeDetails/Nominate";
import { useCustomer } from "../../context/CustomerProvider";
import { useRightSidebar } from "../../hooks/useRightSidebar/useRightSidebar";
import CommonEmployeeList from "../../model/commonEmployeeList/CommonEmployeeList";
import employeeImage from "../../assets/images/EmployeeWrapperImage.svg";
import employeeImage2 from "../../assets/images/EmployeeWrapperImage2.svg";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
const EmployeeDetails = () => {
  const {
    employee,
    setEmployee,
    setError,
    employeeDetails,
    setEmployeeDetails,
    isUpdate,
    setIsUpdate,
    setWorkLocation,
    setDesignation,
    setDepartment,
    setEmployment,
    department,
    employment,
    designation,
    workLocation,
    commonRightSidebar,
  } = useEmployee();

  const popupFilterBoxRef = useRef(null);
  const { open, handleOpen, handleClose } = useModel();
  const {
    open: open1,
    handleOpen: handleOpen1,
    handleClose: handleClose1,
  } = useModel();
  const {
    open: open2,
    handleOpen: handleOpen2,
    handleClose: handleClose2,
  } = useModel();

  const {
    open: open3,
    handleOpen: handleOpen3,
    handleClose: handleClose3,
  } = useModel();

  const {
    open: open4,
    handleOpen: handleOpen4,
    handleClose: handleClose4,
  } = useModel();

  const {
    open: open5,
    handleOpen: handleOpen5,
    handleClose: handleClose5,
  } = useModel();
  const {
    open: open6,
    handleOpen: handleOpen6,
    handleClose: handleClose6,
  } = useModel();

  const { customer } = useCustomer();

  const [rightSidebar, setRightSidebar] = useState(false);
  const [prevHr, setPrevHr] = useState({
    firstName: "",
    lastName: "",
  });
  const [type, setType] = useState("terminate");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [searchFilter, setSearchFilter] = useState(false);
  const [displayedEmployees, setDisplayedEmployees] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadMoreData, setLoadMoreData] = useState(false);
  const [filterConditinally, setFilterConditinally] = useState([]);
  const [filterLoading, setFilterloading] = useState(false);
  const [select, setSelect] = useState({
    department: [],
    designation: [],
    employment: [],
    work_Location: [],
    index: 0,
  });
  
  const [current, setCurrent] = useState("Department");
  const [openFilter, setOpenFilter] = useState(false);
  const limit = 20;
  const [isEmployee, setisEmployee] = useState(false);
  const [isHrNominatedModel, setIsHrNominatedModel] = useState(false);

  const messageChangeHr = {
    title: "Change HR Confirmation",
    content:
      prevHr && prevHr?.firstName && prevHr?.lastName
        ? `Your previous HR was: ${prevHr?.firstName} ${prevHr?.lastName}. Are you sure you want to change your HR?`
        : "Are you sure you want to change your HR?",
    leftBtn: !customer?.data?.isHr?.isHrAssign ? "Okay" : "Change HR",
    rightBtn: "Cancel",
    type: "WarningHr",
  };

  const messageReCapture = {
    title: "Sure to Recapture?",
    content: "Please select the employees you want to recapture.",
    leftBtn: "Yes",
    rightBtn: "Cancel",
    type: "recapture",
  };

  const messageRecaptureForRightSidebar = {
    heading: "Select an employee for Re-Capture.",
    btn1: "Re-Capture",
    type: "reCapture",
  };

  useEffect(() => {
    if (customer && customer?.data && !customer?.data?.isHr?.isHrAssign ) {
      if(isHrNominatedModel){
        handleOpen4();
      }
    }
  }, [customer,isHrNominatedModel]);

  useEffect(() => {
    if (customer && customer?.data && customer?.data?.isHr?.isHrAssign) {
      const hr = employee?.find((h, i) => h?.isHr);
      // console.log(hr?.firstName)
      setPrevHr({
        firstName: hr?.firstName,
        lastName: hr?.lastName,
      });
    }
  }, [customer, employee]);

  const messageTerminate = {
    title: `${
      employeeDetails?.isTerminated ? "Terminated" : "Terminate"
    } Employee Account?`,
    content: `${
      employeeDetails?.isTerminated
        ? "This employee's account has already been terminated. No further action is required."
        : "Are you sure you want to permanently terminate this employee's account? This action cannot be undone."
    }`,

    leftBtn: `${employeeDetails?.isTerminated ? "" : "Terminate"}`,
    rightBtn: `${employeeDetails?.isTerminated ? "Ok" : "Cancel"}`,
    type: employeeDetails?.isTerminated ? "terminated" : "terminate",
  };

  const messageSuspend = {
    title: `${
      employeeDetails?.isTerminated
        ? "Terminated"
        : employeeDetails?.isSuspended
        ? "Suspended"
        : "Suspend"
    } Employee Account?`,
    content: `${
      employeeDetails?.isTerminated
        ? "This employee's account is already terminated. Suspension is not possible."
        : employeeDetails?.isSuspended
        ? "This employee's account is already suspended. You can reactivate it later if needed."
        : "Are you sure you want to suspend this employee's account? The account can be reactivated later, but the user will not have access in the meantime."
    }`,
    leftBtn: `${
      employeeDetails?.isTerminated
        ? ""
        : employeeDetails?.isSuspended
        ? "Suspended"
        : "Suspend"
    }`,
    rightBtn: `${
      employeeDetails?.isTerminated
        ? "Ok"
        : employeeDetails?.isSuspended
        ? "Cancel"
        : "Cancel"
    }`,
    type: `${
      employeeDetails?.isTerminated
        ? "Terminated"
        : employeeDetails?.isSuspended
        ? "Suspended"
        : "Suspend"
    }`,
  };

  useEffect(() => {
    loadMoreEmployees();
  }, [isUpdate]);

  const onEmployeeSelect = (id) => {
    const info = employee.find((e) => e?._id === id);
    if (info) {
      setEmployeeDetails(info);
    }
  };

  const handleTerminate = () => {
    setType("terminate");

    handleOpen2();
  };
  const handleSuspended = () => {
    setType("suspend");
    handleOpen2();
  };
  const loadMoreEmployees = async () => {
    try {
      setLoading(true);

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/employeeListIndividuals-customers`,
        {
          params: { page, limit, isFaceRegistered: true },
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      const { employees: newEmployees, pagination } = response.data.data;
      if (response?.data?.success) {
        setisEmployee(pagination?.totalEmployees === 0);
        setIsHrNominatedModel(pagination?.totalEmployees !== 0);
      }
      if (pagination?.currentPage <= pagination?.totalPages) {
        setEmployee((prevEmployees) => {
          const existingEmployeeIds = new Set(
            prevEmployees.map((employee) => employee._id)
          );

          const filteredNewEmployees = newEmployees.filter(
            (newEmployee) => !existingEmployeeIds.has(newEmployee._id)
          );

          return [...prevEmployees, ...filteredNewEmployees];
        });

        if (newEmployees.length > 0) {
          if (!employeeDetails?._id) {
            setEmployeeDetails(newEmployees[0]);
          }
        }
        setLoading(false);

        setPage(pagination.currentPage + 1);
        setHasMore(pagination.hasNextPage);
      }
    } catch (error) {
      setError("Error fetching employees");
    }
  };

  const fetchSearchResults = async (query) => {
    try {
      if (!query || query.length == 0 || !query === "") {
        setSearchFilter(false);
        return;
      }

      setLoadMoreData(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/employees/searchEmployees?q=${query}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      setFilteredEmployees(data?.data);
      setSearchFilter(true);
    } catch (e) {
      // console.log(e.response.merssage);
    }
  };

  const debouncedFetchResults = useCallback(
    debounce((query) => {
      fetchSearchResults(query);
    }, 1000),
    []
  );

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchTerm(query);
    debouncedFetchResults(query);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (current) {
        if (current === "Department") {
          setFilterConditinally(department);
        } else if (current === "Designation") {
          setFilterConditinally(designation);
        } else if (current === "Employmen..") {
          setFilterConditinally(employment);
        } else if (current === "Work Location") {
          setFilterConditinally(workLocation);
        }
      }
    };
    fetchData();
  }, [current, department]);

  const handleSelect = (i) => {
    setSelect((prev) => ({
      ...prev,
      index: i,
    }));
  };

  const hasData = (select) => {
    return (
      select.department.length > 0 ||
      select.designation.length > 0 ||
      select.employment.length > 0 ||
      select.work_Location.length > 0
    );
  };
  const handleConvertCheck = (filterType, data) => {
    const updatedFilters = filterType.map((item) => {
      if (item._id === data._id) {
        return { ...item, isTick: !item.isTick };
      }
      return item;
    });

    if (current === "Department") {
      const filteredData = updatedFilters?.filter(
        (fil) => fil?.isTick === true
      );

      setSelect((prev) => ({
        ...prev,
        department: filteredData?.map((des) => des?.department.trim()),
      }));
      setDepartment(updatedFilters);
      setFilterConditinally(updatedFilters);
    } else if (current === "Designation") {
      const filteredData = updatedFilters?.filter(
        (fil) => fil?.isTick === true
      );

      setSelect((prev) => ({
        ...prev,

        designation: filteredData?.map((des) => des?.designation.trim()),
      }));
      setDesignation(updatedFilters);
      setFilterConditinally(updatedFilters);
    } else if (current === "Employmen..") {
      const filteredData = updatedFilters?.filter(
        (fil) => fil?.isTick === true
      );

      setSelect((prev) => ({
        ...prev,

        employment: filteredData?.map((emp) => emp?.employmenttype.trim()),
      }));
      setEmployment(updatedFilters);
      setFilterConditinally(updatedFilters);
    } else if (current === `Work Location`) {
      const filteredData = updatedFilters?.filter(
        (fil) => fil?.isTick === true
      );

      setSelect((prev) => ({
        ...prev,
        work_Location: filteredData?.map(
          (loc) => `${loc?.city.trim()} - ${loc?.pincode.trim()}`
        ),
      }));
      setWorkLocation(updatedFilters);
      setFilterConditinally(updatedFilters);
    }
  };

  const handleSelectCheckBox = (data) => {
    if (current === "Department") {
      handleConvertCheck(filterConditinally, data, current);
    } else if (current === "Designation") {
      handleConvertCheck(filterConditinally, data, current);
    } else if (current === "Employmen..") {
      handleConvertCheck(filterConditinally, data, current);
    } else if (current === "Work Location") {
      handleConvertCheck(filterConditinally, data, current);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupFilterBoxRef.current &&
        !popupFilterBoxRef.current.contains(event.target)
      ) {
        setOpenFilter(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openFilter]);

  const applyFilter = async () => {
    if (
      (!select?.department || select?.department.length === 0) &&
      (!select?.designation || select?.designation.length === 0) &&
      (!select?.employment || select?.employment.length === 0) &&
      (!select?.work_Location || select?.work_Location.length === 0)
    ) {
      return;
    }

    const filterData = {};

    if (select?.department && select?.department.length > 0) {
      filterData.department = select.department;
    }

    if (select?.designation && select?.designation.length > 0) {
      filterData.designation = select.designation;
    }

    if (select?.employment && select?.employment.length > 0) {
      filterData.employment = select.employment;
    }

    if (select?.work_Location && select?.work_Location.length > 0) {
      filterData.work_Location = select.work_Location;
    }

    setFilterloading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/employees/filter-conditionally`,
        filterData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success) {
        setFilteredEmployees(response?.data?.data);
        setSearchFilter(true);
        // setOpenFilter(false);
        // handleClearAll("applyFilter");
        setFilterloading(false);
      }
    } catch (error) {
      setFilterloading(false);
    }
  };

  const handleClearAll = (check = "clearAll") => {
    if (check !== "applyFilter") {
      setSearchFilter(false);
    }
    setDepartment(
      department?.map((dep) => ({
        ...dep,
        isTick: false,
      }))
    );
    setEmployment(
      employment?.map((emp) => ({
        ...emp,
        isTick: false,
      }))
    );
    setWorkLocation(
      workLocation?.map((wor) => ({
        ...wor,
        isTick: false,
      }))
    );
    setDesignation(
      designation?.map((des) => ({
        ...des,
        isTick: false,
      }))
    );
    setCurrent("Department");
    setFilterConditinally(
      department?.map((dep) => ({
        ...dep,
        isTick: false,
      }))
    );
    setSelect((prev) => ({
      department: [],
      designation: [],
      employment: [],
      work_Location: [],
      index: 0,
    }));
  };

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    if (scrollTop + clientHeight >= scrollHeight - 5 && !loading) {
      loadMoreEmployees(); // Fetch more employees
    }
  };

  const sortByIsHr = (employeesArray) => {
    const updatedEmployees = employeesArray.map(employee => ({
      ...employee,
      isRetrainFrontend: false,
    }));
    return updatedEmployees.sort((a, b) => b.isHr - a.isHr);
  };

  useEffect(() => {
    if (searchFilter) {
      setDisplayedEmployees(sortByIsHr(filteredEmployees));
    } else {
      setDisplayedEmployees(sortByIsHr(employee));
    }
    
  }, [searchFilter, filteredEmployees, employee]);

  const changeHR = () => {
    handleOpen5();
  };
  const ReCapture = () => {
    handleOpen6();
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#fff",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#09355C",
    },
  }));

  return (
    <Layout>
      {isEmployee ? (
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"100%",width:"100%"}}>
          <h1>No Employee Founds</h1>
        </div>
      ) : (
        <div
          className={` employeeDetails employee-details-container ${
            open4 ? "modal-open" : ""
          }`}
        >
          <div
            className="one"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "70px",
            }}
          >
            <h3>All Employees </h3>
            {
               (filteredEmployees.length > 0 || employee.length) ?
               <div className="listOfBtn">
               <button
                 className="nominateHrBySelect"
                 style={{ padding: "5px 25px", margin: "0 10px" }}
                 onClick={() => ReCapture()}
               >
                 <span>Re-Capture</span>
               </button>
 
               {customer &&
                 customer?.data &&
                 customer?.data?.isHr?.isHrAssign && (
                   <button
                     className="nominateHrBySelect"
                     id="chnageHrColorRemove"
                     style={{ padding: "5px 25px" }}
                     onClick={() => changeHR()}
                   >
                     <span>Change HR</span>
                   </button>
                 )}
             </div> 
             :
             ""

            }
           
          </div>

          <div className="commonBorderForAll employeeDetailsSection">
            {filteredEmployees.length > 0 || employee.length > 0 ? (
              <div
                className={`DetailsOfEmployeeLeft ${
                  employeeDetails?.isReTrainStrict ? "blur" : ""
                }`}
              >
                {employeeDetails?.isReTrainStrict && (
                  <div className="errorMessage">
                    <p>
                      You cannot perform this action because the employee is
                      scheduled for re-training.
                    </p>
                  </div>
                )}

                <div className="Employees_Whole_Data">
                  <div className="Employees_Data_Heading">
                    <div className="Heading_Panel">
                      {!employeeDetails?.isHr ? (
                        <h1>Employee Details</h1>
                      ) : (
                        <h1>HR Details</h1>
                      )}
                    </div>
                    <div className="Heading_Panel_A">
                      <div className="Emp_Action_Button" onClick={handleOpen1}>
                        <p>Edit</p>
                      </div>
                    </div>
                  </div>

                  <div className="containerEmpDetails">
                    <div className="Profile_Data">
                      <div className="profilePic">
                        <img
                          src={
                            employeeDetails?.profilePhoto
                              ? !employeeDetails.isReTrainStrict
                                ? employeeDetails?.profilePhoto
                                : employeeImage2
                              : employeeImage2
                          }
                          alt={`${employeeDetails?.idProofPhoto?.name}'s picture`}
                        />
                        <button className="logDetails" onClick={handleOpen}>
                          <span>Log Details</span>
                        </button>
                      </div>
                    </div>
                    <div className="Profile_Container">
                      <div className="Profile_Content">
                        <div className="Profile_Content_Heading">
                          <p>First Name</p>
                        </div>
                        <div className="T11">
                          <p>:</p>
                        </div>
                        <div className="Profile_Content_Data">
                          <p>{employeeDetails?.firstName}</p>
                        </div>
                      </div>
                      <div className="Profile_Content">
                        <div className="Profile_Content_Heading">
                          <p>Last Name</p>
                        </div>
                        <div className="T11">
                          <p>:</p>
                        </div>
                        <div className="Profile_Content_Data">
                          <p>{employeeDetails?.lastName}</p>
                        </div>
                      </div>
                      <div className="Profile_Content">
                        <div className="Profile_Content_Heading">
                          <p>Employee ID</p>
                        </div>
                        <div className="T11">
                          <p>:</p>
                        </div>
                        <div className="Profile_Content_Data">
                          <p>{employeeDetails?.employeeId}</p>
                        </div>
                      </div>
                      <div className="Profile_Content">
                        <div className="Profile_Content_Heading">
                          <p>D.O.B</p>
                        </div>
                        <div className="T11">
                          <p>:</p>
                        </div>
                        <div className="Profile_Content_Data">
                          <p>
                            {new Date(
                              employeeDetails?.dob
                            ).toLocaleDateString()}
                          </p>
                        </div>
                      </div>
                      <div className="Profile_Content">
                        <div className="Profile_Content_Heading">
                          <p>Gender</p>
                        </div>
                        <div className="T11">
                          <p>:</p>
                        </div>
                        <div className="Profile_Content_Data">
                          <p>{employeeDetails?.gender}</p>
                        </div>
                      </div>
                      <div className="Profile_Content">
                        <div className="Profile_Content_Heading">
                          <p>Email ID (Personal)</p>
                        </div>
                        <div className="T11">
                          <p>:</p>
                        </div>
                        <div className="Profile_Content_Data">
                          <p>{employeeDetails?.emailPersonal}</p>
                        </div>
                      </div>
                      <div className="Profile_Content">
                        <div className="Profile_Content_Heading">
                          <p>Email ID (Official)</p>
                        </div>
                        <div className="T11">
                          <p>:</p>
                        </div>
                        <div className="Profile_Content_Data">
                          <p>{employeeDetails?.emailOfficial}</p>
                        </div>
                      </div>
                      <div className="Profile_Content">
                        <div className="Profile_Content_Heading">
                          <p>Mobile No.</p>
                        </div>
                        <div className="T11">
                          <p>:</p>
                        </div>
                        <div className="Profile_Content_Data">
                          <p>{employeeDetails?.mobileNo}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="Profile_Details">
                  <div className="Profile_Content">
                    <div className="Profile_Content_Heading">
                      <p>Address</p>
                    </div>
                    <div className="T11">
                      <p>:</p>
                    </div>
                    <div className="Profile_Content_Data">
                      <p>{employeeDetails?.address}</p>
                    </div>
                  </div>
                  <div className="Profile_Content">
                    <div className="Profile_Content_Heading">
                      <p>Date of Joining</p>
                    </div>
                    <div className="T11">
                      <p>:</p>
                    </div>
                    <div className="Profile_Content_Data">
                      <p>
                        {new Date(
                          employeeDetails?.joiningDate
                        ).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                  <div className="Profile_Content">
                    <div className="Profile_Content_Heading">
                      <p>Department</p>
                    </div>
                    <div className="T11">
                      <p>:</p>
                    </div>
                    <div className="Profile_Content_Data">
                      <p>{employeeDetails?.department}</p>
                    </div>
                  </div>
                  <div className="Profile_Content">
                    <div className="Profile_Content_Heading">
                      <p>IT</p>
                    </div>
                    <div className="T11">
                      <p>:</p>
                    </div>
                    <div className="Profile_Content_Data">
                      <p>{employeeDetails?.designation}</p>
                    </div>
                  </div>
                  <div className="Profile_Content">
                    <div className="Profile_Content_Heading">
                      <p>Employment Type</p>
                    </div>
                    <div className="T11">
                      <p>:</p>
                    </div>
                    <div className="Profile_Content_Data">
                      <p>{employeeDetails?.employmentType}</p>
                    </div>
                  </div>
                  <div className="Profile_Content">
                    <div className="Profile_Content_Heading">
                      <p>Work Location</p>
                    </div>
                    <div className="T11">
                      <p>:</p>
                    </div>
                    <div className="Profile_Content_Data">
                      <p>{employeeDetails?.workLocation}</p>
                    </div>
                  </div>
                  <div className="Profile_Content">
                    <div className="Profile_Content_Heading">
                      <p>Block No./Building No.</p>
                    </div>
                    <div className="T11">
                      <p>:</p>
                    </div>
                    <div className="Profile_Content_Data">
                      <p>{employeeDetails?.blockOrBuildingNo}</p>
                    </div>
                  </div>
                  <div className="Profile_Content">
                    <div className="Profile_Content_Heading">
                      <p>Shift</p>
                    </div>
                    <div className="T11">
                      <p>:</p>
                    </div>
                    <div className="Profile_Content_Data">
                      <p>{employeeDetails?.shift}</p>
                    </div>
                  </div>
                  <div className="Profile_Content">
                    <div className="Profile_Content_Heading">
                      <p>ID Proof</p>
                    </div>
                    <div className="T11">
                      <p>:</p>
                    </div>
                    <div className="Profile_Content_Data">
                      <p>{employeeDetails?.idProofType}</p>
                    </div>
                  </div>
                  <div className="Profile_Content">
                    <div className="Profile_Content_Heading">
                      <p>ID Proof No.</p>
                    </div>
                    <div className="T11">
                      <p>:</p>
                    </div>
                    <div className="Profile_Content_Data">
                      <p>{employeeDetails?.idProofNo}</p>
                    </div>
                  </div>
                  {/* <div className="Profile_Content">
                    <div className="Profile_Content_Heading">
                      <p>ID Proof Photo</p>
                    </div>
                    <div className="T11">
                      <p>:</p>
                    </div>
                    <div className="Profile_Content_Data" onClick={handleOpen3}>
                      <p>
                        <a
                          href={employeeDetails?.idProofPhoto?.data || "#"} 
                          download={`${
                            employeeDetails?.firstName || "Employee"
                          }_ID.png`} 
                        >
                          Download ID Proof
                        </a>
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
            ) : (
              <EmployeeInfo />
            )}
            {filteredEmployees.length > 0 || employee.length > 0 ? (
              <div className="listOfEmployeeRight">
                <div className="All_Employees_List">
                  {(filteredEmployees.length > 0 || employee.length > 0) && (
                    <div className="EmployeeSearch">
                      <form
                        className="SearchForm"
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <div className="Search_Input_Data">
                          <div
                            className="searchBox"
                            style={{
                              width: "100%",
                            }}
                          >
                            <input
                              type="text"
                              placeholder="Search Employee"
                              value={searchTerm}
                              onChange={handleSearchChange}
                              style={{
                                "--search-icon": `url(${search_icons})`,
                                width: "100%",
                              }}
                            />
                          </div>
                          <div className="Input_Data_Button">
                            {openFilter && (
                              <div
                                className="model_filter"
                                ref={popupFilterBoxRef}
                              >
                                <div className="heading">
                                  <h3 style={{ cursor: "pointer" }}>
                                    Select List
                                  </h3>
                                  <h3
                                    onClick={
                                      hasData(select)
                                        ? handleClearAll
                                        : undefined
                                    }
                                    style={{
                                      ...(hasData(select)
                                        ? {
                                            cursor: "pointer",
                                            color: "#09355C",
                                          }
                                        : {}),
                                    }}
                                  >
                                    Clear All
                                  </h3>
                                </div>
                                <div className="filterBasedOn">
                                  <div className="name">
                                    {[
                                      "Department",
                                      "Designation",
                                      "Employmen..",
                                      "Work Location",
                                    ].map((based, i) => (
                                      <div
                                        className={`nameOfThat ${
                                          i === select?.index && "skyBlue"
                                        }`}
                                        key={i}
                                        onClick={() => {
                                          handleSelect(i);
                                          setCurrent(based);
                                        }}
                                      >
                                        <p>{based}</p>
                                      </div>
                                    ))}
                                  </div>
                                  <div className="borderCenter"></div>
                                  <div className="nameOfChoosen">
                                    {filterConditinally?.map((v, i) => (
                                      <div
                                        className="checkbox_container"
                                        key={i}
                                      >
                                        <div
                                          className="iconsCheckBox"
                                          onClick={() =>
                                            handleSelectCheckBox(v)
                                          }
                                        >
                                          {v?.isTick ? (
                                            <MdCheckBox fontSize={22} />
                                          ) : (
                                            <MdCheckBoxOutlineBlank
                                              fontSize={22}
                                            />
                                          )}
                                        </div>

                                        <div className="nameDesig">
                                          {current === "Department" && (
                                            <p>{v?.department}</p>
                                          )}
                                          {current === "Designation" && (
                                            <p>{v?.designation}</p>
                                          )}
                                          {current === "Employmen.." && (
                                            <p>{v?.employmenttype}</p>
                                          )}

                                          {current === "Work Location" && (
                                            <p>{v?.city}</p>
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <div className="filterBtn">
                                  <button onClick={applyFilter}>
                                    {filterLoading ? (
                                      <span>Filtering</span>
                                    ) : (
                                      <span>Apply Filter</span>
                                    )}
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className="Search_Filter_Data"
                          onClick={() => setOpenFilter((prev) => !prev)}
                        >
                          <BootstrapTooltip title="Filter">
                            <img src={filterIcons} alt="Filter" />
                          </BootstrapTooltip>
                        </div>
                      </form>
                    </div>
                  )}
                  <div className="Employees_Lists" onScroll={handleScroll}>
                    {displayedEmployees.length > 0 ? (
                      displayedEmployees.map((data, i) => (
                        <div
                          key={i}
                          className="Employee_List_Data"
                          onClick={() => onEmployeeSelect(data._id)}
                          tabIndex="0"
                          style={{ padding: "10px" }}
                        >
                          <div className="Employee_Picture">
                            <img
                              src={
                                data?.profilePhoto
                                  ? !data.isReTrainStrict
                                    ? data?.profilePhoto
                                    : employeeImage2
                                  : employeeImage
                              }
                              alt={`${data?.firstName} ${data?.lastName}'s picture`}
                            />
                          </div>
                          <div className="Employee_Content">
                            <h1 style={{ margin: "0 0 7px 0" }}>
                              {data?.firstName} {data?.lastName}
                            </h1>
                            <p style={{ margin: "0 0 7px 0" }}>
                              <strong style={{ fontWeight: "bold" }}>
                                {data?.designation}
                              </strong>
                            </p>
                            <p style={{ margin: "0 0 7px 0" }}>
                              {data?.emailOfficial}
                            </p>
                            <p style={{ margin: "0" }}>{data?.mobileNo}</p>
                          </div>
                          {/* <div className="status_employee">
                          {data?.isTerminated && (
                            <div className="circle_status1"></div>
                          )}
                          {!data?.isTerminated && data?.isSuspended && (
                            <div className="circle_status2"></div>
                          )}
                        </div> */}
                          <div
                            className="isHrForEmployeeDetails"
                            style={{ alignSelf: "center" }}
                          >
                            {data?.isHr && <MdCheckBox fontSize={25} />}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div style={{ padding: "10px", textAlign: "center" }}>
                        No employee found
                      </div>
                    )}
                    {loading && !searchFilter && (
                      <div style={{ padding: "10px", textAlign: "center" }}>
                        <img src={Loader} alt="loader" height={50} width={50} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <EmployeeList />
            )}
          </div>
        </div>
      )}

      <LogDetails
        open={open}
        handleClose={handleClose}
        employeeId={employeeDetails?.employeeId}
        employeeName={employeeDetails?.firstName}
      />
      <EditProfile
        open={open1}
        handleClose={handleClose1}
        setEmployeeDetails={setEmployeeDetails}
        employeeDetails={employeeDetails}
      />
      <Confirm
        open={open2}
        handleClose={handleClose2}
        message={type === "terminate" ? messageTerminate : messageSuspend}
        setEmployeeDetails={setEmployeeDetails}
        employeeDetails={employeeDetails}
      />
      {/* <IdProof
        open={open3}
        handleClose={handleClose3}
        idPic={employeeDetails?.idProofPhoto?.data}
      /> */}
      {open4 && (
        <NominateHr
          open={open4}
          handleClose={handleClose4}
          setRightSidebar={setRightSidebar}
        />
      )}

      <Confirm
        open={open5}
        handleClose={handleClose5}
        message={messageChangeHr}
        setRightSidebar={setRightSidebar}
      />

      <Confirm
        open={open6}
        handleClose={handleClose6}
        message={messageReCapture}
        setRightSidebar={setRightSidebar}
      />
      {rightSidebar && (
        <Nominate
          filteredEmployees={filteredEmployees}
          employee={employee}
          displayedEmployees={displayedEmployees}
          loading={loading}
          handleScroll={handleScroll}
          searchTerm={searchTerm}
          handleSearchChange={handleSearchChange}
          openFilter={openFilter}
          popupFilterBoxRef={popupFilterBoxRef}
          handleClearAll={handleClearAll}
          select={select}
          handleSelect={handleSelect}
          setCurrent={setCurrent}
          filterConditinally={filterConditinally}
          handleSelectCheckBox={handleSelectCheckBox}
          current={current}
          applyFilter={applyFilter}
          filterLoading={filterLoading}
          setOpenFilter={setOpenFilter}
          setRightSidebar={setRightSidebar}
          setFilteredEmployees={setFilteredEmployees}
        />
      )}

      {commonRightSidebar && (
        <CommonEmployeeList
          filteredEmployees={filteredEmployees}
          employee={employee}
          displayedEmployees={displayedEmployees}
          loading={loading}
          handleScroll={handleScroll}
          searchTerm={searchTerm}
          handleSearchChange={handleSearchChange}
          openFilter={openFilter}
          popupFilterBoxRef={popupFilterBoxRef}
          handleClearAll={handleClearAll}
          select={select}
          handleSelect={handleSelect}
          setCurrent={setCurrent}
          filterConditinally={filterConditinally}
          handleSelectCheckBox={handleSelectCheckBox}
          current={current}
          applyFilter={applyFilter}
          filterLoading={filterLoading}
          setOpenFilter={setOpenFilter}
          setFilteredEmployees={setFilteredEmployees}
          messageRecaptureForRightSidebar={messageRecaptureForRightSidebar}
          setDisplayedEmployees={setDisplayedEmployees}
          searchFilter={searchFilter}
          loadMoreEmployees={loadMoreEmployees}
        />
      )}
    </Layout>
  );
};

export default EmployeeDetails;
