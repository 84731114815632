import { useCustomer } from "../../context/CustomerProvider";

// export default RedirectPage;
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../../assets/gif/Loader.gif"; // Import your loader gif

// const RedirectPage = () => {
//   const { setIsAuthenticated, isUpdate, setIsUpdate } = useCustomer();

//   const navigate = useNavigate();
//   const { token } = useParams();
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(true); // Loading state

//   useEffect(() => {
//     const verifyToken = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_BASE_URL}/user/verifyCustomerId`,
//           {
//             headers: {
//               Authorization: token,
//             },
//           }
//         );
//         if (response.data.exists) {
//           localStorage.setItem("token", token);
//           setIsAuthenticated(true);
//           setIsUpdate(!isUpdate);
//           navigate("/emp-details");
//         } else {
//           // If the customer ID does not exist, log them out and redirect

//           setError("Customer ID not found. Please log in.");
//           navigate("/login");
//         }
//       } catch (error) {
//         console.error("Error verifying token:", error);
//         // Redirect to login on error
//         navigate("/login");
//       } finally {
//         setLoading(false); // Stop loading after the API call
//       }
//     };

//     if (token) {
//       verifyToken();
//     } else {
//       // Redirect to login if no token
//       navigate("/login");
//       setLoading(false); // Stop loading if there's no token
//     }      
//   }, [token, navigate]);

//   // Show loader while data is being fetched
//   if (loading) {
//     return (
//       <div className="Loader">
//         <img src={Loader} alt="Loading" />
//       </div>
//     );
//   }

//   return null; // Optionally return null when not loading
// };

// export default RedirectPage;


const RedirectPage = () => {
  const { setIsAuthenticated, isUpdate, setIsUpdate } = useCustomer();
  const navigate = useNavigate();
  const { token } = useParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const verifyToken = async () => {
      // console.log(token);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/user/verifyCustomerId`,
          {
            headers: {
              Authorization: token, 
            },
          }
        );

        if (response.data.exists) {
          console.log("checkIn",response.data.exists)
          localStorage.setItem("token", token); 
          setIsAuthenticated(true); 
          setIsUpdate(!isUpdate); 
          navigate("/emp-details"); 
        } else {
          console.log("not true1")
          setError("Customer ID not found. Please log in.");
          setLoading(false); 

          navigate("/login"); 
      
        }
      } catch (error) {
        console.log("not true2")

        console.error("Error verifying token:", error);
        setLoading(false); 

        setError("An error occurred. Please log in.");
        navigate("/login"); 
        
      } finally {
        setLoading(false); 
      

      }
    };

    if (token) {
      verifyToken();
    } else {
      setError("No token provided. Please log in.");
      setLoading(false);
      navigate("/login"); 

    }
  }, [token]);

  if (loading) {
    return (
      <div className="Loader">
        <img src={Loader} alt="Loading" />
      </div>
    );
  }

  if (error) {
    return <div className="Error">{error}</div>;
  }

  return null; 
};
export default RedirectPage;
