import React, { useRef, useState, useCallback } from "react";
import calender_icons from "../../../assets/images/Calendar month.svg";
import search_icons from "../../../assets/images/searchIcons.svg";
import { useEmployee } from "../../../context/EmpProvider";
import axios from "axios";
import DateRange from "../../../model/dateRange/DateRange";
import { UseDatePicker } from "../../../hooks/useDatePicker/UseDatePicker";
import { GiCheckMark } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";
import debounce from "lodash/debounce";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const Heading = ({ tab, setTab, setIsFilter }) => {
  const [selectDate, setSelectDate] = useState();
  const {
    attendanceData,
    setAttendanceData,
    attendanceFilter,
    setAttendanceFilter,
    filteredData,
    setFilteredData,
    completeLog,
    setCompleteLog,
    logLength,
    setLogLength,
    selectedDate,
    setReportLoading,
    setSelectedDate,
  } = useEmployee();

  const { selectDatePicker, setSelectDatePicker } = UseDatePicker();
  const [sortLog, setSortLog] = useState("");
  const handleIconClick = () => {
    setSelectDatePicker(!selectDatePicker);
  };

  const fetchSearchResults = async (query) => {
    try {
      // if (!query || query.length == 0 || !query === "") {
      //   setSearchFilter(false);
      //   return;
      // }
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/liveTracker/serch-livetracker?q=${query}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      if (data?.success) {
        setLogLength(data?.data?.length);
        setAttendanceData(data?.data);
      }
    } catch (e) {
      // console.log(e.response.merssage);
    }
  };

  const debouncedFetchResults = useCallback(
    debounce((query) => {
      fetchSearchResults(query);
    }, 1000),
    []
  );

  const searchEmployees = (data) => {
    setIsFilter(true);
    if (tab === 0) {
      const attendanceInfo = attendanceFilter.filter((employee) => {
        return (
          employee.employeeId.toLowerCase().includes(data.toLowerCase()) ||
          employee.employeeName.toLowerCase().includes(data.toLowerCase())
        );
      });
      setLogLength(attendanceInfo?.length);
      setAttendanceData(attendanceInfo);
      // debouncedFetchResults(data.toLowerCase());
    } else {
      const attendanceInfo = completeLog.filter((employee) => {
        return (
          employee.employeeId.toLowerCase().includes(data.toLowerCase()) ||
          employee.employeeName.toLowerCase().includes(data.toLowerCase())
        );
      });
      setLogLength(attendanceInfo?.length);

      setFilteredData(attendanceInfo);
    }
  };

  const filterRecord = async (filterType) => {
    try {
      setReportLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/liveTracker/filter-livetracker`,
        {
          params: {
            filterType: filterType,
            startDateFilter: selectedDate?.startDate,
            endDateFilter: selectedDate?.endDate,
          },
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log(response);
      if (response?.statusText === "OK") {
        setAttendanceData(response?.data);
        setAttendanceFilter(response?.data || []);

        setLogLength(response?.data?.length);

        setReportLoading(false);

        setSelectedDate({
          startDate: "",
          endDate: "",
        });
        setSelectDatePicker(false);
      }
    } catch (error) {
      setReportLoading(false);

      console.error(
        "Error fetching attendance reports:",
        error.response?.data || error.message
      );
    }
  };

  const filterRecordCompleteLog = async (filterType) => {
    try {
      setReportLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/liveTracker/filtercompletelog-livetracker`,
        {
          params: {
            filterType: filterType,
            startDateFilter: selectedDate?.startDate,
            endDateFilter: selectedDate?.endDate,
          },
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log(response);
      if (response?.statusText === "OK") {
        setFilteredData(response?.data);
        setCompleteLog(response.data);

        setLogLength(response?.data?.length);

        setReportLoading(false);
        setSelectedDate({
          startDate: "",
          endDate: "",
        });
        setSelectDatePicker(false);
      }
    } catch (error) {
      setReportLoading(false);

      console.error(
        "Error fetching attendance reports:",
        error.response?.data || error.message
      );
    }
  };
  const hnadleClearAll = () => {
    // setIsFilter(false);
    window.location.reload();
  };
  
  const handleSortLog = (e) => {
    if (e.target.value === "Clear All") {
      hnadleClearAll();
      return;
    }
    setIsFilter(true);
    if (tab === 0) {
      if (e.target.value !== "Sort") {
        setSortLog(e.target.value);
        filterRecord(e.target.value);
      }
    } else {
      if (e.target.value !== "Sort") {
        setSortLog(e.target.value);
        filterRecordCompleteLog(e.target.value);
      }
    }
  };

  const getAttendanceBasedOnDate = () => {
    setIsFilter(true);

    if (tab === 0) {
      if (selectedDate && selectedDate?.startDate && selectedDate?.endDate) {
        filterRecord("Custom");
      }
    } else {
      if (selectedDate && selectedDate?.startDate && selectedDate?.endDate) {
        filterRecordCompleteLog("Custom");
      }
    }
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#fff",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#09355C",
    },
  }));

  return (
    <div className="heading_Att_reports">
      <div className="left_side">
        <div className="one">
          <h3>Total .</h3>
          <p>&nbsp; {logLength} records found</p>
        </div>
        <div id="two">
          {["Attendance Reports", "Complete Log"].map((t, i) => (
            <button
              key={i}
              id={`${tab === i || 0 ? "activeTab" : ""}`}
              onClick={() => {
                setTab(i);
                setSortLog("Sort");
                setIsFilter(false);
              }}
            >
              <span>{t}</span>
            </button>
          ))}
        </div>
      </div>
      <div className="right_side">
        <div
          className="calender datePickerReportSections"
          style={{ margin: "10px" }}
        >
          {selectDatePicker ? (
            <>
              <div
                style={{
                  margin: "0 5px",
                  background: "#0A3B66",
                  height: "30px",
                  width: "30px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "Center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSelectDatePicker(false);
                }}
              >
                <RxCross2 fontSize={25} color="white" />
              </div>

              {selectedDate &&
                selectedDate?.startDate &&
                selectedDate?.endDate && (
                  <div
                    style={{
                      margin: "0 5px",
                      background: "#0A3B66",
                      height: "30px",
                      width: "30px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "Center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      getAttendanceBasedOnDate();
                    }}
                  >
                    <GiCheckMark fontSize={20} color="white" />
                  </div>
                )}
            </>
          ) : (
            <BootstrapTooltip title="Date Picker">
            <b>
            <img
              src={calender_icons}
              alt="calendar_icons"
              onClick={handleIconClick}
              style={{ cursor: "pointer", margin: "0 10px" }}
            />
            </b>
          </BootstrapTooltip>
          
          )}

          {selectDatePicker && <DateRange type="report" />}
        </div>

        <div className="custom-select-container">
          <label htmlFor="shift-select">Select Shift:</label>
          <select
            id="shift-select"
            value={sortLog}
            onChange={(e) => handleSortLog(e)}
            className="custom-select"
          >
            {[
              "Sort",
              "Today",
              "Weekly",
              "Monthly",
              "Yearly",
              "All",
              "Clear All",
            ].map((sort) => (
              <option key={sort} value={sort}>
                {sort}
              </option>
            ))}
          </select>
        </div>

        <div className="searchBox">
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => searchEmployees(e.target.value)}
            style={{ "--search-icon": `url(${search_icons})` }}
          />
        </div>
      </div>
    </div>
  );
};

export default Heading;
