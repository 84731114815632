// import React from "react";
// import countriesTimezones from "./countriesTimezones.json"; // Adjust the path as necessary

// const TimeFormatterForReportDetails = ({ country, state, time }) => {
//   const getTimeByCountry = (country, state, time) => {
//     let locale = "en-US"; // Default locale
//     let timeZone = "UTC"; // Default timezone

//     // Get locale and timezone from the JSON file
//     const countryInfo = countriesTimezones[country.toLowerCase()];
//     if (countryInfo) {
//       locale = countryInfo.locale;

//       // Determine the timezone based on whether a state is provided
//       timeZone = countryInfo.timeZone; // Start with the predefined timezone for the country

//       // Check if state-specific time zones exist and override if found
//       if (countryInfo.timeZones && state) {
//         const stateInfo = countryInfo.timeZones[state.toLowerCase()];
//         if (stateInfo) {
//           timeZone = stateInfo.timeZone; // Override with state timezone if available
//         }
//       }
//     } else {
//       console.warn(`No timezone data found for country: ${country}`);
//     }

//     try {
//       // Get the current date
//       const now = new Date();
//       // Create a new date object by combining the current date with the given time
//       const [hours, minutes, seconds] = time.split(":");
//       const date = new Date(
//         now.getFullYear(),
//         now.getMonth(),
//         now.getDate(),
//         hours,
//         minutes,
//         seconds
//       );

//       const formattedTime = new Intl.DateTimeFormat(locale, {
//         timeZone: timeZone,
//         timeStyle: "long", // Change to 'short' if you want a shorter time format
//         dateStyle: undefined, // Exclude date from the formatting
//       }).format(date);

//       // Remove the timezone abbreviation
//       return formattedTime.replace(/\s+[A-Z]+$/, "").trim();
//     } catch (error) {
//       console.error("Invalid locale or timezone:", error);
//       return null;
//     }
//   };

//   const currentTime = getTimeByCountry(country, state, time);

//   return <div>{currentTime}</div>;
// };

// export default TimeFormatterForReportDetails;

import React from "react";
import countriesTimezones from "./countriesTimezones.json"; // Adjust the path as necessary

const TimeFormatterForReportDetails = ({ country, state, time }) => {
  const getTimeByCountry = (country, state, time) => {
    if (!time || time === "NA") {
      return "NA";
    }

    let locale = "en-US"; // Default locale
    let timeZone = "UTC"; // Default timezone

    const countryInfo = countriesTimezones[country.toLowerCase()];
    if (countryInfo) {
      locale = countryInfo.locale;
      timeZone = countryInfo.timeZone; // Start with the predefined timezone for the country

      if (countryInfo.timeZones && state) {
        const stateInfo = countryInfo.timeZones[state.toLowerCase()];
        if (stateInfo) {
          timeZone = stateInfo.timeZone; // Override with state timezone if available
        }
      }
    } else {
      // console.warn(`No timezone data found for country: ${country}`);
    }

    try {
      // Ensure the time is valid before processing
      if (!/^\d{2}:\d{2}:\d{2}$/.test(time)) {
        // console.error(`Invalid time format: ${time}`);
        return "N/A";
      }

      const [hours, minutes, seconds] = time.split(":");

      // Ensure hours, minutes, and seconds are valid integers
      if (
        isNaN(hours) ||
        isNaN(minutes) ||
        isNaN(seconds) ||
        hours < 0 ||
        hours > 23 ||
        minutes < 0 ||
        minutes > 59 ||
        seconds < 0 ||
        seconds > 59
      ) {
        // console.error(`Invalid time values: ${time}`);
        return "N/A";
      }

      // Get the current date
      const now = new Date();
      // Create a new date object by combining the current date with the given time
      const date = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        hours,
        minutes,
        seconds
      );

      if (isNaN(date)) {
        // console.error(`Invalid date: ${time}`);
        return "N/A";
      }

      // Use Intl.DateTimeFormat to format the date with the correct locale and timezone
      const formattedTime = new Intl.DateTimeFormat(locale, {
        timeZone: timeZone,
        timeStyle: "long", // 'long' for full time format
        dateStyle: undefined, // Exclude date from the formatting
      }).format(date);

      // console.log(formattedTime, "formatted time");

      return formattedTime.replace(/\s+[A-Z]+$/, "").trim();
    } catch (error) {
      // console.error("Error formatting time:", error);
      return "N/A";
    }
  };

  const currentTime = getTimeByCountry(country, state, time);

  return <div>{currentTime}</div>;
};

export default TimeFormatterForReportDetails;
