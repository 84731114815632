import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import { useCustomer } from "../../context/CustomerProvider";
import { useEmployee } from "../../context/EmpProvider";
import axios from "axios";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: 0,
  outline: 0,
};
const AutoReports = ({
  open,
  handleClose,
  selectedData,
  setSelectedData,
  update,
  setUpdate,
  getShift
}) => {
  const navigate = useNavigate();
  const { customer, setCustomer, setIsAuthenticated } = useCustomer();



  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState("");
  const [day, setDay] = useState("Next Day");
  const [email, setEmail] = useState("");
  const timeRef = useRef();
  const dayRef = useRef();
  const emailRef = useRef();

useEffect(()=>{

  setTime(selectedData?.time || "");
  setDay(selectedData?.day || "");
  setEmail(selectedData?.email || "")

},[update,selectedData])


  const validate = () => {
    if (!email) {
      emailRef.current.focus();
      return true;
    }
    if (!time) {
      timeRef.current.focus();

      return true;
    }
    if (!day) {
      dayRef.current.focus();
      return true;
    }

    return false;
  };

  const handleSubmit = async () => {
    try {
      if (validate()) {
    console.log("dsgdsg",time, day, email)

        return;
      }
    
      setLoading(true);
      const { data } = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/masters/shiftname/edit`,
        { time, day, email,_id:selectedData?._id },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      if (data?.status_code===200) {
        handleClose()
        setUpdate(false);
        setSelectedData(null);
        getShift()
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className=" Popup_Box_Shift">
          <div className="shiftTimeHeading">
            <h2>Shift Time: 06:00AM - 03:00PM</h2>
          </div>
          <div className="inputFields_shift">
            <div className="">Email Id :</div>
            <div className="">
              <input
                type="email"
                ref={emailRef}
                placeholder="email@gmail.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="inputFields_shift">
            <div className="">Time :</div>
            <div className="">
              <input
              id="timeInputFields"
                type="time"
                ref={timeRef}
                value={time}
                onChange={(e) => setTime(e.target.value)}
              />
            </div>
          </div>
          <div className="inputFields_shift">
            <div className="">Day :</div>
            <div className="">
              <select
                value={day}
                ref={dayRef}
                onChange={(e) => setDay(e.target.value)}
              >
                <option value="Same Day">Same Day</option>
                <option value="Next Day">Next Day</option>
              </select>
            </div>
          </div>
          <div className="autoReportBtn">
            <button onClick={handleSubmit} disabled={loading}>Submit</button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default AutoReports;
