import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Masters.css";
import Department from "../masterComponents/Department/Department";
import Location from "../masterComponents/Location/Location";
import Building from "../masterComponents/Building/Building";
import ShiftName from "../masterComponents/ShiftName/ShiftName";
import { BaseURL } from "../../../BaseURL";



const Masters = ({ setShowWarningPopup, setChange, change }) => {

  const [activeIndex, setActiveIndex] = useState(0);
  const [dataLengths, setDataLengths] = useState([]);

  useEffect(() => {
    const fetchDataLengths = async () => {
      try {
        const endpoints = [
          "/masters/department",
          "/masters/location",
          "/masters/building",
          "/masters/shiftname",
        ];
        const requests = endpoints.map((endpoint) =>
          axios.get(
            `${BaseURL}${endpoint}/get`,

            {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token"),
              },
            }
          )
        );
        const responses = await Promise.all(requests);
        const lengths = responses.map((response, index) => {
          switch (index) {
            case 0:
              return response.data.departments?.length || 0;
            case 1:
              return response.data.locations?.length || 0;
            case 2:
              return response.data.buildings?.length || 0;
            case 3:
              return response.data.shiftnames?.length || 0;
            default:
              return 0;
          }
        });
        setDataLengths(lengths);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchDataLengths();
  }, []);

  const handleClick = (index) => {
    const Heading = document.querySelectorAll(".Mark_Name")[index];

    if (Heading.classList.contains("next") || activeIndex + 1 === index) {
      setActiveIndex(index);
    }
  };

  const menuItems = [
    { title: "Department" },
    { title: "Location" },
    { title: "Building" },
    { title: "Shift Name" },
  ];

  const renderActiveComponent = () => {
    switch (activeIndex) {
      case 0:
        return <Department setChange={setChange} change={change} />;
      case 1:
        return <Location setChange={setChange} change={change} />;
      case 2:
        return <Building setChange={setChange} change={change} />;
      case 3:
        return <ShiftName setChange={setChange} change={change} />;
      default:
        return null;
    }
  };

  return (
  
     <div className="Masters_Container">
      <div className="Side_Bar">
        <div className="Side_Menu">
          {menuItems.map((item, index) => (
            <div
              className={`Side_Heading ${
                activeIndex === index ? "active" : ""
              }`}
              key={index}
              onClick={() => handleClick(index)}
            >
              <div
                className={`Mark ${dataLengths[index] > 0 ? "active" : ""}`}
              ></div>
              <div
                className={`Mark_Name ${activeIndex > index ? "next" : ""} ${
                  activeIndex === index ? "active" : ""
                } ${activeIndex + 1 === index ? "next" : ""} ${
                  dataLengths[index] > 0 ? "next" : ""
                }`}
              >
                <h2>{item.title}</h2>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="Main_Bar">{renderActiveComponent()}</div>
    </div>
  
   
  );
};

export default Masters;
