import axios from "axios";
import { useState, useEffect, useRef } from "react";
import Layout from "../../components/Layout/AdminLayout/Layout";
import LiveTrackerSkeleton from "../../skeleton/liveTracker/LiveTrackerSkeleton";
import { useCustomer } from "../../context/CustomerProvider";
import TimeFormatter from "../../utils/gloablDateTimeFunction";
import { useNotifications } from "../../context/NotificationsProvider";
import search_icons from "../../assets/images/searchIcons.svg";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useAttendanceTable } from "../../hooks/useTime/attendanceTable";
import ViewImage from "../../model/ViewImage/ViewImage";
import { useModel } from "../../hooks/useModel/useModel";
import okulrImage from "../../assets/images/Okulr_Logo.png";
import { TbArrowsDiagonal } from "react-icons/tb";
import { FaDownload, FaFileCsv, FaFilePdf } from "react-icons/fa";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";

const LiveTracker = () => {
  const [liveTracker, setLiveTracker] = useState([]);
  const [filterLiveTracker, setFilterLiveTrackerLiveTracker] = useState([]);
  const [tab, setTab] = useState(0);
  const [exportTab, setexportTab] = useState(0);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const lastDataRef = useRef([]); // Store the last received data
  const { customer } = useCustomer();
  const [selectedImage, setSelectedImage] = useState(null);
  const [companyLogo, setComplanyLogo] = useState("");

  const {
    setLoading: setLoadingNotifications,
    setError: setErrorNotifications,
    connected,
    subscribe,
  } = useNotifications();
  useEffect(() => {
    setComplanyLogo(customer?.data?.businesslogo);
  }, [customer]);
  const { getTimeByCountry } = useAttendanceTable();
  const { handleClose, handleOpen, open } = useModel();
  const pollLiveTracker = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/liveTracker/fetch`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      const data = response.data.data;
      if (response.data.success) {
        const sortedData = data.sort(
          (a, b) => new Date(a?.inTime) - new Date(b?.inTime)
        );
        setLiveTracker(sortedData);
        setFilterLiveTrackerLiveTracker(sortedData);
        setError(null);
      }
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    pollLiveTracker();
  }, []);

  useEffect(() => {
    if (connected) {
      const unsubscribe = subscribe("notifyReactLiveTracker", (data) => {
        let liveTrackerData = data?.payload || null;
        console.log(liveTrackerData);

        setLiveTracker((prevLiveTracker) => {
          const existingNotifications = new Set(
            prevLiveTracker?.map((live) => live.employeeId)
          );

          if (
            liveTrackerData &&
            !existingNotifications.has(liveTrackerData.employeeId)
          ) {
            const combinedNotifications = [...prevLiveTracker, liveTrackerData];

            const sortedNotifications = combinedNotifications.sort(
              (a, b) => new Date(a?.inTime) - new Date(b?.inTime)
            );
            return sortedNotifications;
          }

          return prevLiveTracker;
        });

        setFilterLiveTrackerLiveTracker((prevLiveTracker) => {
          const existingNotifications = new Set(
            prevLiveTracker?.map((live) => live.employeeId)
          );

          if (
            liveTrackerData &&
            !existingNotifications.has(liveTrackerData.employeeId)
          ) {
            const combinedNotifications = [...prevLiveTracker, liveTrackerData];

            const sortedNotifications = combinedNotifications.sort(
              (a, b) => new Date(a?.inTime) - new Date(b?.inTime)
            );
            return sortedNotifications;
          }

          return prevLiveTracker;
        });
      });

      return () => {
        if (unsubscribe) {
          console.log("Cleaning up liveTracker");
          unsubscribe();
        }
      };
    }
  }, [connected, subscribe]);

  const searchEmployees = (data) => {
    const attendanceInfo = filterLiveTracker.filter((employee) => {
      return (
        employee.employeeId?.toLowerCase().includes(data?.toLowerCase()) ||
        employee.employeeName?.toLowerCase().includes(data?.toLowerCase())
      );
    });

    setLiveTracker(attendanceInfo);
  };
  const checkIsLate = (inTime, shift, graceTime) => {
    console.log(inTime, shift, graceTime);

    const [a, b] = shift.split("-")[0].split(":");
    const officeStartTime = new Date();
    officeStartTime.setHours(Number(a), Number(b), 0, 0);

    const gracePeriod = Number(graceTime) * 60 * 1000;
    const gracePeriodEnd = new Date(officeStartTime.getTime() + gracePeriod);

    let userInTime;
    if (isNaN(Date.parse(inTime))) {
      const [time, meridian] = inTime.split(" ");
      const [hours, minutes, seconds] = time.split(":").map(Number);
      userInTime = new Date();
      userInTime.setHours(
        meridian?.toLowerCase() === "pm" && hours !== 12 ? hours + 12 : hours,
        minutes,
        seconds || 0,
        0
      );
    } else {
      userInTime = new Date(inTime);
    }

    if (userInTime > gracePeriodEnd) {
      const lateTime = userInTime - gracePeriodEnd;
      const hoursLate = Math.floor(lateTime / (1000 * 60 * 60));
      const minutesLate = Math.floor(
        (lateTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const secondsLate = Math.floor((lateTime % (1000 * 60)) / 1000);

      return {
        message: `${hoursLate}hr ${minutesLate}min ${secondsLate}sec Late`,
        success: true,
      };
    } else {
      return { message: "On Time", success: false };
    }
  };
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#fff",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#09355C",
    },
  }));
  const handleImageZoom = (img, name) => {
    setSelectedImage({
      name: name,
      pic: img,
    });
    handleOpen();
  };

  const handleSort = (tab) => {
    try {
      if (tab === "On Time") {
        const sortliveTracker = filterLiveTracker?.filter((employee) => {
          return !checkIsLate(
            getTimeByCountry(
              customer?.data.country,
              employee.inTime,
              customer?.data.state,
              employee?.shift
            )?.newTime,
            employee?.shift,
            employee?.graceTime
          )?.success;
        });
        setLiveTracker(sortliveTracker);
      } else if (tab === "Late") {
        const sortliveTracker = filterLiveTracker?.filter((employee) => {
          return checkIsLate(
            getTimeByCountry(
              customer?.data.country,
              employee.inTime,
              customer?.data.state,
              employee?.shift
            )?.newTime,
            employee?.shift,
            employee?.graceTime
          )?.success;
        });
        setLiveTracker(sortliveTracker);
      } else {
        setLiveTracker(filterLiveTracker);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleIsLateTimeCalculate = (employee) => {
    return checkIsLate(
      getTimeByCountry(
        customer?.data.country,
        employee.inTime,
        customer?.data.state,
        employee?.shift
      )?.newTime,
      employee?.shift,
      employee?.graceTime
    )?.message;
  };
  const getBase64ImageFromURL = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.log("Error fetching image:", error);
      return null;
    }
  };
  const handleExportPdf = async () => {
    try {
      const dataForSummeryApi = liveTracker?.map((live) => {
        const {
          employeeId,
          employeeName,
          inTime,
          graceTime,
          shift,
          inTimeSnippet,
        } = live;
        const time = handleIsLateTimeCalculate(live);
        return {
          employeeId,
          employeeName,
          inTime: new Date(inTime).toLocaleTimeString(),
          inTimeSnippet,
          graceTime,
          shift,
          isLate: time,
        };
      });

      const doc3 = new jsPDF({
        unit: "mm", // Units in mm
        format: [220, 297], // Custom format (Width, Height) (increased width, A4 height)
      });

      // ✅ Define consistent left margin for centering
      const leftMargin = 15;

      if (companyLogo) {
        const logoImage = new Image();
        logoImage.src = await getBase64ImageFromURL(companyLogo);

        logoImage.onload = async () => {
          const maxWidth = 60; // Reduce logo size for A4 fit
          const maxHeight = 30;
          let logoWidth = logoImage.width;
          let logoHeight = logoImage.height;

          const aspectRatio = logoWidth / logoHeight;
          if (logoWidth > maxWidth) {
            logoWidth = maxWidth;
            logoHeight = maxWidth / aspectRatio;
          }
          if (logoHeight > maxHeight) {
            logoHeight = maxHeight;
            logoWidth = maxHeight * aspectRatio;
          }

          // ✅ Place logo at the center-top
          const pageWidth = doc3.internal.pageSize.width;
          const logoX = (pageWidth - logoWidth) / 2; // Center logo
          doc3.addImage(
            await getBase64ImageFromURL(companyLogo),
            "JPEG",
            logoX,
            10,
            logoWidth,
            logoHeight
          );
          // Function to fetch the image in base64 format

          // ✅ Table Headers
          const tableColumn3 = [
            "S.No.",
            "Employee ID",
            "Employee Name",
            "Shift Timing",
            "In-Timing",
            "Is-Late",
            "InSnippet",
          ];
          for (const member of dataForSummeryApi) {
            if (member.inTimeSnippet) {
              member.inTimeSnippet = await getBase64ImageFromURL(
                member.inTimeSnippet
              );
            }
          }
          // ✅ Table Data
          const tableRows3 = dataForSummeryApi.map((member, index) => {
            return [
              index + 1,
              member.employeeId || "--",
              member.employeeName || "--",
              member.shift || "--",
              member.inTime || "--",
              member.isLate || "--",
              "",
            ];
          });

          // ✅ Centered Title
          const title = "Live Tracker";
          const textWidth = doc3.getTextWidth(title);
          const textX = (pageWidth - textWidth) / 2;
          doc3.setFontSize(14);
          doc3.text(title, textX, 45);
          doc3.setTextColor(46, 46, 46);

          // ✅ Calculate Total Table Width
          const totalTableWidth = 15 + 30 + 35 + 30 + 30 + 35 + 35;
          const tableLeftMargin = (pageWidth - totalTableWidth) / 2; // Center table

          // ✅ Draw Table
          autoTable(doc3, {
            head: [tableColumn3],
            body: tableRows3,
            startY: 55,
            margin: { left: tableLeftMargin, right: tableLeftMargin }, // Center the table
            styles: { overflow: "linebreak", cellWidth: "wrap" },
            bodyStyles: { cellPadding: { top: 5, bottom: 5, left: 2 } },
            headStyles: {
              cellPadding: { top: 5, bottom: 5, left: 2 },
              halign: "center", // ✅ Center headers
            },
            columnStyles: {
              0: { cellWidth: 15, halign: "center" },
              1: { cellWidth: 30, halign: "center" },
              2: { cellWidth: 35, halign: "center" },
              3: { cellWidth: 30, halign: "center" },
              4: { cellWidth: 30, halign: "center" },
              5: { cellWidth: 35, halign: "center" },
              6: { cellWidth: 35, halign: "center" },
            },
            didDrawCell: (data) => {
              if (data.section === "body" && data.column.index === 6) {
                const rowIndex = data.row.index;
                if (rowIndex >= 0 && rowIndex < dataForSummeryApi.length) {
                  const member = dataForSummeryApi[rowIndex];
                  const imageBase64 = member.inTimeSnippet;

                  if (imageBase64) {
                    try {
                      doc3.addImage(
                        `${imageBase64}`,
                        "JPEG",
                        data.cell.x + (data.cell.width - 12) / 2, // Center image
                        data.cell.y + (data.cell.height - 12) / 2, // Center vertically
                        12,
                        12
                      );
                    } catch (error) {
                      console.log("Error adding image:", error);
                    }
                  } else {
                    doc3.autoTableText(
                      "----",
                      data.cell.x + data.cell.width / 2, // Center text horizontally
                      data.cell.y + data.cell.height / 2 + 2, // Center text vertically
                      { halign: "center", valign: "middle" }
                    );
                  }
                }
              }
            },
            horizontalPageBreakRepeat: true,
          });

          // ✅ Save PDF
          doc3.save(`live-tracker.pdf`);
        };

        logoImage.onerror = () => {
          console.error("Failed to load the logo image.");
        };
      }
    } catch (error) {
      console.log("Error generating PDF:", error);
    }
  };

  const handleExportCsv = () => {
    const data = liveTracker?.map((live) => {
      const { inTimeSnippet, ...rest } = live;
      const { employeeId, employeeName, inTime, graceTime, shift } = rest;
      const time = handleIsLateTimeCalculate(live);
      return {
        employeeId,
        employeeName,
        inTime: new Date(inTime).toLocaleTimeString(),
        graceTime,
        shift,
        isLate: time,
      };
    });
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    saveAs(blob, "livetracker.xlsx");
  };

  const handleExport = (tab) => {
    if (tab === "Export PDF") {
      handleExportPdf();
    } else if (tab === "Export CSV") {
      handleExportCsv();
    }
  };

  return (
    <Layout>
      <section className="liveTracker">
        <div
          className="one heading_Att_reports"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "70px",
          }}
        >
          <h3>Live Attendance</h3>
          <div
            className="searchBox"
            style={{
              display: "flex",
              justifyContent: "between",
              alignItems: "center",
            }}
          >
            <div className="liveTrackerExport">
              {loading ? (
                <div className="shimmer-container_liveTracker">
                  {[1, 2, 3].map((_, i) => (
                    <div key={i} className="shimmer-button"></div>
                  ))}
                </div>
              ) : (
                ["Export PDF", "Export CSV"].map((t, i) => (
                  <button
                    key={i}
                    id={`${exportTab === i ? "activeTab" : ""}`}
                    onClick={() => {
                      setexportTab(i);
                      handleExport(t);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: `${
                        exportTab === i ? "space-between" : "center"
                      }`,
                      alignItems: "center",
                    }}
                  >
                    <span> {exportTab === i && <FaDownload size={20} />}</span>

                    <span>
                      <b>{t} </b>
                    </span>
                  </button>
                ))
              )}
            </div>

            <div id="two">
              {loading ? (
                <div className="shimmer-container_liveTracker">
                  {[1, 2, 3].map((_, i) => (
                    <div key={i} className="shimmer-button"></div>
                  ))}
                </div>
              ) : (
                ["All", "On Time", "Late"].map((t, i) => (
                  <button
                    key={i}
                    id={`${tab === i ? "activeTab" : ""}`}
                    onClick={() => {
                      setTab(i);
                      handleSort(t);
                    }}
                  >
                    <span>
                      <b>
                        {t} {tab === i && `(${liveTracker.length})`}{" "}
                      </b>
                    </span>
                  </button>
                ))
              )}
            </div>
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => searchEmployees(e.target.value)}
              style={{
                "--search-icon": `url(${search_icons})`,
                width: "300px",
              }}
            />
          </div>
        </div>
        <div className="commonBorderForAll">
          {error ? (
            <div className="logDetailsLoader">
              <h1>No Attendance Found</h1>
            </div>
          ) : (
            <>
              <div className="heading ">
                <h1>Today’s Attendance: ({filterLiveTracker?.length})</h1>
              </div>
              {loading && <LiveTrackerSkeleton />}
              <div className="listOfAttendanceEmployee">
                {liveTracker?.map((employee, index) => {
                  return (
                    <div className="card" key={index}>
                      <div
                        onClick={() =>
                          handleImageZoom(
                            employee.inTimeSnippet,
                            employee.employeeName
                          )
                        }
                        className="upper"
                        id="upperImageHover"
                        style={{
                          border: !checkIsLate(
                            getTimeByCountry(
                              customer?.data.country,
                              employee.inTime,
                              customer?.data.state,
                              employee?.shift
                            )?.newTime,
                            employee?.shift,
                            employee?.graceTime
                          )?.success
                            ? "2px solid greenyellow"
                            : "2px solid red",
                          cursor: "pointer",
                          height: "200px",
                        }}
                      >
                        {employee.inTimeSnippet ? (
                          <img
                            src={employee.inTimeSnippet}
                            alt="employeePic"
                            id="imgLiveTracker"
                          />
                        ) : (
                          "No snippet available"
                        )}

                        <div className="overlay">
                          <TbArrowsDiagonal color="white" fontSize={35} />
                        </div>
                      </div>
                      <div className="lower">
                        <div className="details">
                          <div className="name_heading">Name</div>
                          <div className="dot">:</div>
                          <div className="name">
                            <BootstrapTooltip title={employee.employeeName}>
                              <b>
                                {employee.employeeName?.length > 10
                                  ? `${employee.employeeName.slice(0, 10)}...`
                                  : employee.employeeName}
                              </b>
                            </BootstrapTooltip>
                          </div>
                        </div>

                        <div className="details">
                          <div className="name_heading">Emp. ID</div>
                          <div className="dot">:</div>
                          <div className="name">
                            <BootstrapTooltip title={employee.employeeId}>
                              <b>
                                {employee.employeeId?.length > 8
                                  ? `${employee.employeeId.slice(0, 9)}...`
                                  : employee.employeeId}
                              </b>
                            </BootstrapTooltip>
                          </div>
                        </div>

                        <div className="details">
                          <div className="name_heading">In Time</div>
                          <div className="dot">:</div>
                          <div className="name">
                            <b>
                              {customer.data.country && customer.data.state && (
                                <TimeFormatter
                                  country={customer.data.country}
                                  state={customer.data.state}
                                  time={employee?.inTime}
                                />
                              )}
                            </b>
                          </div>
                        </div>

                        <div className="timeCheck">
                          <p>
                            {checkIsLate(
                              getTimeByCountry(
                                customer?.data.country,
                                employee.inTime,
                                customer?.data.state,
                                employee?.shift
                              )?.newTime,
                              employee?.shift,
                              employee?.graceTime
                            )?.success && (
                              <b>
                                {
                                  checkIsLate(
                                    getTimeByCountry(
                                      customer?.data.country,
                                      employee.inTime,
                                      customer?.data.state,
                                      employee?.shift
                                    )?.newTime,
                                    employee?.shift,
                                    employee?.graceTime
                                  )?.message
                                }
                              </b>
                            )}
                          </p>

                          <p>
                            {!checkIsLate(
                              getTimeByCountry(
                                customer?.data.country,
                                employee.inTime,
                                customer?.data.state,
                                employee?.shift
                              )?.newTime,
                              employee?.shift,
                              employee?.graceTime
                            )?.success && (
                              <b>
                                {
                                  checkIsLate(
                                    getTimeByCountry(
                                      customer?.data.country,
                                      employee.inTime,
                                      customer?.data.state,
                                      employee?.shift
                                    )?.newTime,
                                    employee?.shift,
                                    employee?.graceTime
                                  )?.message
                                }
                              </b>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </section>
      <ViewImage open={open} handleClose={handleClose} pic={selectedImage} />
    </Layout>
  );
};

export default LiveTracker;
