import React from "react";
import "./MastersShimmer.css";

const MastersShimmer = ({ height }) => {
  return (
    <div className="shimmer-Box">
      <div className="shimmer-Master">
        {[...Array(10)].map((_, index) => (
          <div
            key={index}
            className="shimmerMaster"
            style={{ height: height }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default MastersShimmer;
