import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useEmployee } from "../../../context/EmpProvider";
import autoTable from "jspdf-autotable";
import { jsPDF } from "jspdf";
import TimeFormatter from "../../../utils/globalReportDetailsTimeFormatter";
import { useCustomer } from "../../../context/CustomerProvider";
import TableUi from "../../../skeleton/reports/TableUi";
import { useAttendanceTable } from "../../../hooks/useTime/attendanceTable";
import Pagination from "@mui/material/Pagination";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import ViewImage from "../../../model/ViewImage/ViewImage";
import { TbArrowsDiagonal } from "react-icons/tb";
import { useModel } from "../../../hooks/useModel/useModel";

const CompleteLog = ({ isFilter, setIsFilter }) => {
  const [data, setData] = useState([]);
  const { filteredData, setFilteredData, setCompleteLog, reportLoading } =
    useEmployee();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const { customer } = useCustomer();
  const [companyLogo, setComplanyLogo] = useState("");
  const [isLimit, setIsLimit] = useState(false);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState("");
  const [page, setPage] = React.useState(1);
  const [paginationLoader, setPaginationLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const { handleClose, handleOpen, open } = useModel();

  const { getTimeByCountryfForReports } = useAttendanceTable();
  useEffect(() => {
    setComplanyLogo(customer?.data?.businesslogo);
  }, [customer]);

  useEffect(() => {
    let isMounted = true;

    const fetchCompleteLog = async () => {
      try {
        if (!localStorage.getItem("token")) {
          throw new Error("Customer ID is not available in localStorage");
        }
        setPaginationLoader(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/liveTracker/getCompleteLogOfEmployee?page=${page}&limit=${limit}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
          }
        );
        if (isMounted) {
          setData(response.data?.result);
          setCompleteLog(response.data?.result);
          setFilteredData(response.data?.result);
          setTotalPage(response?.data?.totalPages);
        }
      } catch (err) {
        if (isMounted) {
          setError(err.message);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
          setPaginationLoader(false);
        }
      }
    };

    fetchCompleteLog();
    return () => {
      isMounted = false;
    };
  }, [limit, page]);

  useEffect(() => {
    if (selectedDate) {
      const filtered = data.map((employee) => {
        const filteredLogs = employee.logs.filter(
          (log) =>
            new Date(log.date).toISOString().split("T")[0] === selectedDate
        );
        return { ...employee, logs: filteredLogs };
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [selectedDate, data]);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleRefresh = () => {
    window.location.reload();
  };
  const formatDate = (dateString) => {
    return moment(dateString).format("DD-MM-YYYY");
  };

  // const calculateOfficeTime = (inTime, outTime) => {
  //   if (!inTime || !outTime) return "----";

  //   const inDate = new Date(`1970-01-01T${inTime}Z`);
  //   const outDate = new Date(`1970-01-01T${outTime}Z`);

  //   if (isNaN(inDate.getTime()) || isNaN(outDate.getTime())) return "----";

  //   const totalOfficeTime = outDate - inDate;
  //   const hours = Math.floor(totalOfficeTime / (1000 * 60 * 60));
  //   const minutes = Math.floor(
  //     (totalOfficeTime % (1000 * 60 * 60)) / (1000 * 60)
  //   );
  //   const seconds = Math.floor((totalOfficeTime % (1000 * 60)) / 1000);

  //   return `${hours}hrs : ${minutes}mins : ${seconds}sec`;
  // };

  const handleVisibleLimitBox = () => {
    setIsLimit((prev) => !prev);
  };
  const getBase64ImageFromURL = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.log("Error fetching image:", error);
      return null;
    }
  };

  const handleLogDownload = async () => {
    try {
      const doc3 = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [297, 210],
      });

      if (companyLogo) {
        // Load the logo image to calculate its dimensions
        const logoImage = new Image();
        logoImage.src = await getBase64ImageFromURL(companyLogo);

        logoImage.onload = async () => {
          const originalWidth = logoImage.width;
          const originalHeight = logoImage.height;

          // Set maximum dimensions
          const maxWidth = 70;
          const maxHeight = 35;

          let logoWidth = originalWidth;
          let logoHeight = originalHeight;

          // Calculate aspect ratio and adjust width/height to fit within max dimensions
          const aspectRatio = originalWidth / originalHeight;

          if (logoWidth > maxWidth) {
            logoWidth = maxWidth;
            logoHeight = maxWidth / aspectRatio;
          }

          if (logoHeight > maxHeight) {
            logoHeight = maxHeight;
            logoWidth = maxHeight * aspectRatio;
          }

          // Add the logo to the PDF with the adjusted size
          doc3.addImage(
            await getBase64ImageFromURL(companyLogo),
            "JPEG",
            10,
            10,
            logoWidth,
            logoHeight
          );

          // Continue with generating the table and the rest of the PDF

          const tableColumn3 = [
            "S.No.",
            "Date",
            "Employee ID",
            "Employee Name",
            "Shift Timing",
            "In-Timing",
            "InSnippet",
            "Out-Timing",
            "Out-Snippet",
            "In-Office Time",
          ];

          let serialNumber = 1;
          const tableRows3 = await Promise.all(
            filteredData.flatMap((employee) =>
              employee.logs.flatMap((log) => {
                const logDate = formatDate(employee.inoutDate);
                return log.logEntries.map(async (entry) => {

                  const inTimeSnippetUrl = (entry.inTimeSnippet && entry.inTimeSnippet!=="No snippet available")
                    ? await getBase64ImageFromURL(entry.inTimeSnippet)
                    : "";
// console.log(entry.outTimeSnippet)
                  const outTimeSnippetUrl = (entry.outTimeSnippet && entry.outTimeSnippet!=="No snippet available")
                    ? await getBase64ImageFromURL(entry.outTimeSnippet)
                    : "";
// console.log(outTimeSnippetUrl==="" && "outTimeSnippetUrl");
                  let inOfficeTime =
                    entry.inTime && entry.outTime
                      ? calculateTotalTime(
                          employee?.logs?.[0]?.logEntries,
                          customer.data.state,
                          customer.data.country
                        )
                      : "----";
                  return [
                    serialNumber++,
                    logDate,
                    employee.employeeId || "----",
                    employee.employeeName || "----",
                    employee.shiftTiming || "----",
                    (customer.data.country &&
                      getTimeByCountryfForReports(
                        customer.data.country,

                        customer.data.state,
                        entry.inTime
                      )) ||
                      "----",
                    inTimeSnippetUrl &&
                    inTimeSnippetUrl !==
                      "data:image/jpeg;base64,No snippet available"
                      ? { imageUrl: inTimeSnippetUrl }
                      : "----",
                    (customer.data.country &&
                      getTimeByCountryfForReports(
                        customer.data.country,

                        customer.data.state,
                        entry.outTime
                      )) ||
                      "----",
                    outTimeSnippetUrl &&
                    outTimeSnippetUrl !==
                      "data:image/jpeg;base64,No snippet available"
                      ? { imageUrl: outTimeSnippetUrl }
                      : "----",
                    (inOfficeTime =
                      inOfficeTime === "NA" ? "----" : inOfficeTime),
                  ];
                });
              })
            )
          );

          const pageWidth = doc3.internal.pageSize.width;
          const totalTableWidth = 15 + 30 + 30 + 35 + 35 + 20 + 25 + 25 + 25; 

          const leftMargin = (pageWidth - totalTableWidth) / 2;
          const title = "In-Out Time Attendance Summary";
          const textWidth = doc3.getTextWidth(title);
          const textX = (pageWidth - textWidth) / 2; 

          doc3.text(title, textX, 40);

          autoTable(doc3, {
            head: [tableColumn3],
            body: tableRows3,
            startY: 50,
            margin: { top: 50, left: 10, right: 10 },
            styles: { overflow: "linebreak", cellWidth: "wrap" },
            bodyStyles: { cellPadding: { top: 9, bottom: 9, left: 2 } },
            headStyles: { cellPadding: { top: 9, bottom: 9, left: 2 } },
            columnStyles: {
              0: { cellWidth: 10 },
              1: { cellWidth: 30 },
              2: { cellWidth: 35 },
              3: { cellWidth: 35 },
              4: { cellWidth: 30 },
              5: { cellWidth: 30 },
              6: { cellWidth: 25 },
              7: { cellWidth: 30 },
              8: { cellWidth: 25 },
              9: { cellWidth: 38 },
            },
            didDrawCell: (data) => {
              if (
                (data.column.index === 6 || data.column.index === 8) &&
                data.cell.raw &&
                data.cell.raw.imageUrl
              ) {
                const imageUrl = data.cell.raw.imageUrl;

                if (imageUrl) {
                  doc3.addImage(
                    imageUrl,
                    "JPEG",
                    data.cell.x + 2,
                    data.cell.y + 2,
                    23,
                    23
                  );
                } else {
                  doc3.autoTableText("----", data.cell.x + 3, data.cell.y + 4);
                }
              }
            },

            horizontalPageBreakRepeat: true,
          });

          doc3.save(`attendance.pdf`);
        };

        logoImage.onerror = () => {
          console.error(
            "Failed to load the logo image from the base64 string."
          );
        };
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const calculateTotalTime = (employeesTime, state, country) => {
    try {
      let latestInTime = getTimeByCountryfForReports(
        country,
        state,
        employeesTime[0].inTime
      );
      let lastOutTime = null;
      employeesTime.forEach((entry) => {
        if (entry.outTime) {
          const outTimeDate = entry.outTime;
          // console.log(entry.outTime)
          if (outTimeDate && outTimeDate !== "N/A") {
            // console.log(outTimeDate)
            // console.log(outTimeDate , lastOutTime,outTimeDate > lastOutTime)
            if (lastOutTime && outTimeDate > lastOutTime) {
              lastOutTime = outTimeDate;
            } else {
              lastOutTime = outTimeDate;
            }
          }
          //  console.log(lastOutTime)
        }
      });
      //  console.log(lastOutTime)
      lastOutTime = getTimeByCountryfForReports(country, state, lastOutTime);
      if (!latestInTime || !lastOutTime) {
        return "N/A";
      }
      const convertTo24HourFormat = (timeString) => {
        const date = new Date(`1970-01-01 ${timeString}`);
        if (isNaN(date.getTime())) {
          return null;
        }
        // console.log(date, "date");
        return date;
      };
      const inDate = convertTo24HourFormat(latestInTime);
      const outDate = convertTo24HourFormat(lastOutTime);
      if (
        !inDate ||
        !outDate ||
        isNaN(inDate.getTime()) ||
        isNaN(outDate.getTime())
      ) {
        return "NA";
      }
      if (outDate < inDate) {
        return "NA";
      }
      const totalOfficeTime = outDate - inDate;
      const hours = Math.floor(totalOfficeTime / (1000 * 60 * 60));
      const minutes = Math.floor(
        (totalOfficeTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((totalOfficeTime % (1000 * 60)) / 1000);
      let calculatedTime = `${hours}hrs : ${minutes}mins : ${seconds}sec`;
      return calculatedTime;
    } catch (e) {
      return "NA";
    }
  };

  const handleImageZoom = (img, name) => {
    if (!img || img === "No snippet available") {
      return;
    }
    setSelectedImage({
      name: name,
      pic: img,
    });
    handleOpen();
  };
  if (reportLoading) {
    return <TableUi />;
  }
  if (loading) return <TableUi />;

  if (error)
    return (
      <div className="list_of_dataLoading">
        <h1>No Employees Logs Found</h1>
        <button className="Refresh" onClick={handleRefresh}>
          Refresh
        </button>
      </div>
    );

  const tableHeading = [
    { id: 1, name: "S.NO" },
    { id: 2, name: "Date" },
    { id: 3, name: "Employee ID" },
    { id: 4, name: "Employee Name" },
    { id: 5, name: "Shift Timing" },
    { id: 6, name: "In-Timing" },
    { id: 7, name: "In-Timing Snippet" },
    { id: 8, name: "Out-Timing" },
    { id: 9, name: "Out-Timing Snippet" },
    { id: 10, name: "In-Office Time" },
  ];
  const handleOutSnippetExtract = (a) => {
    const extractinTimeSnippet = [];
    a?.forEach((extractInTime) => {
      if (extractInTime.outTimeSnippet) {
        const Snippet = extractInTime.outTimeSnippet;
        if (Snippet && Snippet !== "No snippet available") {
          extractinTimeSnippet.push(Snippet);
        }
      }
    });
    return extractinTimeSnippet;
  };
  const handleInSnippetExtract = (a) => {
    const extractinTimeSnippet = [];
    a?.forEach((extractInTime) => {
      if (extractInTime.inTimeSnippet) {
        const Snippet = extractInTime.inTimeSnippet;
        if (Snippet && Snippet !== "No snippet available") {
          extractinTimeSnippet.push(Snippet);
        }
      }
    });
    return extractinTimeSnippet;
  };
  const handleInTimeExtract = (a) => {
    const extractNullIntime = [];
    a?.forEach((extractInTime) => {
      if (extractInTime.inTime) {
        const inTime = extractInTime.inTime;
        if (inTime && inTime !== "N/A") {
          extractNullIntime.push(inTime);
        }
      }
    });
    return extractNullIntime;
  };

  const handleOutTimeExtract = (a) => {
    const extractNullIntime = [];
    a?.forEach((extractInTime) => {
      if (extractInTime.outTime) {
        const inTime = extractInTime.outTime;
        if (inTime && inTime !== "N/A") {
          extractNullIntime.push(inTime);
        }
      }
    });
    console.log(extractNullIntime);
    return extractNullIntime;
  };

  return (
    <div className="attendanceTable">
      <div className="list" id="pdfContent">
        <table id="employee-table">
          <thead>
            <tr className="tableAttendanceHeading">
              {tableHeading.map((h) => (
                <th key={h.id}>{h.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData?.map((employee, i) => {
                const TotalTime = calculateTotalTime(
                  employee?.logs?.[0]?.logEntries,
                  customer.data.state,
                  customer.data.country
                );
                let sNo;
                if (page === 1) {
                  sNo = i + 1;
                } else {
                  sNo = i + 1 + limit * page;
                }
                return (
                  <tr key={employee._id} className="tableDataName">
                    <td style={{ verticalAlign: "top" }}>{sNo}</td>

                    <td style={{ verticalAlign: "top" }}>
                      <div>{formatDate(employee?.createdAtDate)}</div>
                    </td>

                    <td style={{ verticalAlign: "top" }}>
                      {employee.employeeId}
                    </td>

                    <td style={{ verticalAlign: "top" }}>
                      {employee.employeeName}
                    </td>

                    <td style={{ verticalAlign: "top" }}>
                      {employee?.shiftTiming}
                    </td>

                    <td style={{ verticalAlign: "top" }}>
                      {handleInTimeExtract(
                        employee?.logs?.[0]?.logEntries
                      )?.map((emp, index) => (
                        <div
                          key={index}
                          style={{
                            marginBottom: "10px",
                            height: "42px",
                            width: "40px",
                          }}
                        >
                          <div>
                            {emp &&
                            customer.data.state &&
                            customer.data.country ? (
                              <TimeFormatter
                                state={customer.data.state}
                                country={customer.data.country}
                                time={emp}
                              />
                            ) : (
                              "NA"
                            )}
                          </div>
                        </div>
                      ))}
                    </td>

                    <td style={{ verticalAlign: "top" }}>
                      {handleInSnippetExtract(
                        employee?.logs?.[0]?.logEntries
                      )?.map((emp, index) => (
                        <div
                          key={index}
                          style={{
                            marginBottom: "10px",
                            height: "42px",
                            width: "40px",
                          }}
                        >
                          <div
                            className="attendanceTablePic"
                            onClick={() =>
                              handleImageZoom(emp, employee.employeeName)
                            }
                          >
                            {emp ? (
                              emp === "No snippet available" ? (
                                "No snippet available"
                              ) : (
                                <img
                                  style={{ height: "42px", width: "40px" }}
                                  src={`${emp}`}
                                  alt="Out Time Snippet"
                                />
                              )
                            ) : (
                              "No snippet available"
                            )}
                            {emp && emp !== "No snippet available" && (
                              <div className="overlayAttendanceTablePic">
                                <TbArrowsDiagonal color="white" fontSize={20} />
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </td>

                    <td style={{ verticalAlign: "top" }}>
                      {handleOutTimeExtract(
                        employee?.logs?.[0]?.logEntries
                      )?.map((emp, index) => (
                        <div
                          key={index}
                          style={{
                            marginBottom: "10px",
                            height: "42px",
                            width: "40px",
                          }}
                        >
                          <div>
                            {emp &&
                            customer.data.state &&
                            customer.data.country ? (
                              <TimeFormatter
                                state={customer.data.state}
                                country={customer.data.country}
                                time={emp}
                              />
                            ) : (
                              "NA"
                            )}
                          </div>
                        </div>
                      ))}
                    </td>

                    <td style={{ verticalAlign: "top" }}>
                      {handleOutSnippetExtract(
                        employee?.logs?.[0]?.logEntries
                      )?.map((emp, index) => (
                        <div
                          key={index}
                          style={{
                            marginBottom: "10px",
                            height: "42px",
                            width: "40px",
                          }}
                        >
                          <div
                            className="attendanceTablePic"
                            onClick={() =>
                              handleImageZoom(emp, employee.employeeName)
                            }
                          >
                            {emp ? (
                              emp === "No snippet available" ? (
                                "No snippet available"
                              ) : (
                                <img
                                  style={{ height: "42px", width: "40px" }}
                                  src={`${emp}`}
                                  alt="Out Time Snippet"
                                />
                              )
                            ) : (
                              "No snippet available"
                            )}

                            {emp && emp !== "No snippet available" && (
                              <div className="overlayAttendanceTablePic">
                                <TbArrowsDiagonal color="white" fontSize={20} />
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </td>

                    <td style={{ verticalAlign: "top" }}>
                      {TotalTime ? TotalTime : "NA"}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="10" style={{ textAlign: "center" }}>
                  <h1> No employee data found</h1>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="button_download">
        {!isFilter && (
          <div className="pagination">
            <div className="leftPagination">
              <div className="boxOfPagination" onClick={handleVisibleLimitBox}>
                <span>{limit}</span>
                <span>
                  {!isLimit ? (
                    <IoIosArrowDown fontSize={20} />
                  ) : (
                    <IoIosArrowUp fontSize={20} />
                  )}
                </span>
              </div>
              {isLimit && (
                <div className="dropDownPagination">
                  {[10, 30, 50, 100, 150]?.map((s, i) => (
                    <div
                      className={`skipPage ${
                        i !== 0 && i !== 4 && "skipPageMargin"
                      }`}
                      onClick={() => {
                        setLimit(s);
                        setIsLimit(!isLimit);
                      }}
                    >
                      <span key={i}>{s}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="rightPagination">
              <div className="boxOfPaginationRight">
                <div style={{ position: "relative" }}>
                  <Pagination
                    count={totalPage}
                    disabled={paginationLoader}
                    sx={{
                      "& .Mui-selected": {
                        backgroundColor: "#0A3B66",
                        color: "#FFFFFF",
                      },
                      "& .MuiPaginationItem-root:hover": {
                        backgroundColor: "#D3D3D3",
                        color: "#FFFFFF",
                      },
                    }}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <button onClick={handleLogDownload} className="downloadBtn">
          <span>Download</span>
        </button>
      </div>
      <ViewImage open={open} handleClose={handleClose} pic={selectedImage} />
    </div>
  );
};

export default CompleteLog;
