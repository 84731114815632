import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import Masters from "../../../pages/masters/CompanyMasters/Masters";
import EmployeeMasters from "../../../pages/masters/EmployeeMasters/EmployeeMasters";
import { useLocation } from "react-router-dom";
import Topbar from "../../../pages/masters/masterHeader/masterHeader";

const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState("company-masters");

  useEffect(() => {
    console.log("✅ Layout Mounted");
  }, []);

  const handleTabChange = (tab) => {
    if (activeTab !== tab) {  // ✅ Prevents unnecessary state updates
      console.log("Switched to:", tab);
      setActiveTab(tab);
    }
  };

  return (
    <div className="adminLayout">
      <div className="upper">
        <Navbar />
      </div>
      <div className="below">
        <div className="left">
          <Sidebar />
        </div>
        <div className="right">
        {pathname === "/masters-header" && (
            <>
              <Topbar onTabChange={handleTabChange} />
              {activeTab === "company-masters" && <Masters />}
              {activeTab === "employee-masters" && <EmployeeMasters />}
            </>
          )}
          {children}</div>
      </div>
    </div>
  );
};

export default Layout;
