import React, { useState, useEffect } from "react";
import Edit from "../../../../assets/masters/Edit.svg";
import axios from "axios";
import { BaseURL } from "../../../../BaseURL";
import Dropdown from "../../../../assets/masters/Dropdown.svg";
import NoMasters from "../../../../assets/masters/NoMasters.svg";
import MastersShimmer from "../../../../skeleton/Masters/MastersShimmer";
import Loader from "../../../../assets/masters/Loader.gif";

const Building = ({ setChange, change }) => {
  const [buildings, setBuildings] = useState([]);
  const [locations, setLocations] = useState([]);
  const [formData, setFormData] = useState({
    buildingno: "",
    location: "",
  });
  const [inputErrors, setInputErrors] = useState({
    buildingno: false,
    location: false,
  });
  const [buttontext, setButtonText] = useState("Submit");
  const [showedit, setShowEdit] = useState(false);
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const [submitloader, setSubmitLoader] = useState(false);
 
  useEffect(() => {
    fetchLocations();
    getBuildings();
  }, []);

  const getBuildings = async () => {
    try {
      setLoader(true);

      const response = await axios.get(`${BaseURL}/masters/building/get`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      if (response.data.status_code === 200) {
        setBuildings(response.data.buildings);
      }
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  const fetchLocations = async () => {
    try {
      const response = await axios.get(
        `${BaseURL}/masters/location/get`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      setLocations(response.data.locations);
    } catch (error) {
      console.error("Error fetching designations:", error);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setInputErrors({ ...inputErrors, [name]: false });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (!value.trim()) {
      setInputErrors({ ...inputErrors, [name]: true });
    }
  };
  const handleClick = async () => {
    const errors = {
      buildingno: !formData.buildingno.trim(),
      location: !formData.location.trim(),
    };

    setInputErrors(errors);

    const hasErrors = Object.values(errors).some((error) => error);
    if (!hasErrors) {
      if (buttontext === "Submit") {
        try {
          setSubmitLoader(true);

          await axios.post(`${BaseURL}/masters/building/add`, formData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
          });
          setFormData((prevData) => ({
            ...prevData,
            buildingno: "",
            location: "",
          }));
          getBuildings();
          setChange(!change);
        } catch (err) {
          console.log(err);
        } finally {
          setSubmitLoader(false);
        }
      } else if (buttontext === "Update") {
        try {
          setSubmitLoader(true);

          await axios.put(`${BaseURL}/masters/building/edit`, formData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
          });
          setFormData((prevData) => ({
            ...prevData,
            buildingno: "",
            location: "",
          }));
          setButtonText("Submit");
          getBuildings();
        } catch (err) {
          console.log(err);
        } finally {
          setSubmitLoader(false);
        }
      }
    }
  };
  const handleEdit = (building) => {
    setButtonText("Update");
    setFormData(building);
    setShowEdit(false);
  };

  return (
    <>
      {submitloader && (
        <>
          <div className="Loader main">
            <img src={Loader} alt="Loading" />
          </div>
        </>
      )}
      {showedit && (
        <>
          <div className="Loader">
            <div className="Popup_Box confirm">
              <div className="Popup_Text">
                <h1>Are you Sure?</h1>
                <p>
                  You want to edit the master details. Employees linked with
                  this details will get updated.
                </p>
              </div>
              <div className="Popup_Button confirm">
                <h1
                  onClick={() => {
                    handleEdit(data);
                  }}
                >
                  Edit
                </h1>
                <button
                  onClick={() => {
                    setShowEdit(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="Main_Heading">
        <div className="Heading_Section">
          <h2>Add Building</h2>
          <div className="Input_Boxes">
            <div className="Input_Field">
              <div className="Label">Building No./ID</div>
              <input
                type="text"
                name="buildingno"
                value={formData.buildingno}
                onChange={handleInputChange}
                onBlur={handleBlur}
                style={{
                  borderColor: inputErrors.buildingno ? "red" : "#ccc",
                }}
              />
            </div>
            <div className="Input_Field">
              <div className="Custom_Select">
                <select
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}
                  required
                  onBlur={handleBlur}
                  style={{
                    borderColor: inputErrors.location ? "red" : "#ccc",
                  }}
                >
                  <option value="" disabled></option>
                  {locations !== undefined &&
                    locations.map((location) => (
                      <option
                        key={location._id}
                        value={`${location.city} - ${location.pincode}`}
                      >
                        {location.city} - {location.pincode}
                      </option>
                    ))}
                </select>
                <img
                  src={Dropdown}
                  alt="Dropdown arrow"
                  className="Dropdown_Arrow"
                />
                <div className="Label">Location</div>
              </div>
            </div>
          </div>
          <div className="Heading_Submit">
            <button onClick={handleClick}>{buttontext}</button>
          </div>
        </div>
        <div className="Heading_Details">
          <h2>Building Details</h2>
          {loader ? (
            <MastersShimmer height={"35px"} />
          ) : (
            <div className="All_Headings">
              {buildings.length > 0 ? (
                buildings.map((building, index) => (
                  <div className="Heading_Box" key={index}>
                    <h3>{building.buildingno}</h3>
                    <h4>{building.location}</h4>
                    <div className="Heading_Button">
                      <img
                        src={Edit}
                        className="Edit"
                        alt="Edit"
                        onClick={() => {
                          setShowEdit(true);
                          setData(building);
                        }}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <div className="Image_Container">
                  <img src={NoMasters} alt="No Masters" />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Building;
