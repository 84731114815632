import { io } from 'socket.io-client';

let socket;

export const initiateSocketConnection = (token) => {
    socket = io(`https://fatsbackend.facesense.in/backend`, {
        auth: { token },
    });
    socket.on('connect_error', (error) => {
        console.error('Socket connection error:', error);
    });
};

export const disconnectSocket = () => {
    if (socket) {
        socket.disconnect();
        console.log('Socket disconnected');
    }
};

export const subscribeToEvent = (eventName, callback) => {
    if (!socket) return;
    socket.on(eventName, callback);
    return () => {
        socket.off(eventName, callback);
    };
};

export const emitEvent = (eventName, data) => {
    if (!socket) return;
    socket.emit(eventName, data);
};
