import React from "react";
import "./ShimmerAutoReport.css"; // Add styles for shimmer effect

const ShimmerAutoReport = () => {
  return (
    <div className="shimmer-autoReports">
      <div className="shimmer-autoReports_child">
        <div className="shimmer-autoReports-one">
          <div className="shimmer-shape shimmer-title"></div>
          <div className="shimmer-shape shimmer-text"></div>
          <div className="shimmer-shape shimmer-text"></div>
          <div className="shimmer-shape shimmer-text"></div>
        </div>

        <div className="shimmer-autoReports-two">
          <div className="shimmer-shape shimmer-long-text"></div>
        </div>

        <div className="shimmer-autoReports-three">
          <div className="shimmer-shape shimmer-button"></div>
        </div>
      </div>
    </div>
  );
};

export default ShimmerAutoReport;
