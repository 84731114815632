import React, { useState, useEffect } from "react";
import "./Department.css";
import Edit from "../../../../assets/masters/Edit.svg";
import axios from "axios";
import NoMasters from "../../../../assets/masters/NoMasters.svg";
import MastersShimmer from "../../../../skeleton/Masters/MastersShimmer";
import Loader from "../../../../assets/masters/Loader.gif";
import { BaseURL } from "../../../../BaseURL";


const Department = ({ setChange, change }) => {
  const [showedit, setShowEdit] = useState(false);
  const [data, setData] = useState({});
  const [departments, setDepartments] = useState([]);
  const [formData, setFormData] = useState({
    department: "",
  });
  const [inputErrors, setInputErrors] = useState({
    department: false,
  });
  const [buttontext, setButtonText] = useState("Submit");
  const [loader, setLoader] = useState(false);
  const [submitloader, setSubmitLoader] = useState(false);

  useEffect(() => {
    getDepartments();
  }, []);
 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setInputErrors({ ...inputErrors, [name]: false });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (!value.trim()) {
      setInputErrors({ ...inputErrors, [name]: true });
    }
  };

  const getDepartments = async () => {
    try {
      setLoader(true);
      const response = await axios.get(`${BaseURL}/masters/department/get`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      if (response.data.status_code === 200) {
        setDepartments(response.data.departments);
      }
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  const handleClick = async () => {
    const errors = {
      department: !formData.department.trim(),
    };

    setInputErrors(errors);

    const hasErrors = Object.values(errors).some((error) => error);
    if (!hasErrors) {
      if (buttontext === "Submit") {
        try {
          setSubmitLoader(true);

          await axios.post(`${BaseURL}/masters/department/add`, formData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
          });
          setFormData((prevData) => ({
            ...prevData,
            department: "",
          }));
          getDepartments();
          setChange(!change);
        } catch (err) {
          console.log(err);
        } finally {
          setSubmitLoader(false);
        }
      } else if (buttontext === "Update") {
        try {
          setSubmitLoader(true);
          await axios.put(`${BaseURL}/masters/department/edit`, formData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
          });
          getDepartments();
          setFormData((prevData) => ({
            ...prevData,
            department: "",
          }));
          setButtonText("Submit");
        } catch (err) {
          console.log(err);
        } finally {
          setSubmitLoader(false);
        }
      }
    }
  };
  const handleEdit = (department) => {
    setButtonText("Update");
    setFormData(department);
    setShowEdit(false);
  };

  return (
    <>
      {submitloader && (
        <>
          <div className="Loader main">
            <img src={Loader} alt="Loading" />
          </div>
        </>
      )}
      {showedit && (
        <>
          <div className="Loader">
            <div className="Popup_Box confirm">
              <div className="Popup_Text">
                <h1>Are you Sure?</h1>
                <p>
                  You want to edit the master details. Employees linked with
                  this details will get updated.
                </p>
              </div>
              <div className="Popup_Button confirm">
                <h1
                  onClick={() => {
                    handleEdit(data);
                  }}
                >
                  Edit
                </h1>
                <button
                  onClick={() => {
                    setShowEdit(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="Main_Heading">
        <div className="Heading_Section">
          <h2>Add Department</h2>
          <div className="Input_Boxes">
            <div className="Input_Field">
              <div className="Label">Department</div>
              <input
                type="text"
                name="department"
                value={formData.department}
                onChange={handleInputChange}
                onBlur={handleBlur}
                style={{
                  borderColor: inputErrors.department ? "red" : "#ccc",
                }}
              />
            </div>
          </div>
          <div className="Heading_Submit">
            <button onClick={handleClick}>{buttontext}</button>
          </div>
        </div>
        <div className="Heading_Details">
          <h2>Department Details</h2>
          {loader ? (
            <MastersShimmer height={"50px"} />
          ) : (
            <div className="All_Headings">
              {departments.length > 0 ? (
                departments.map((department, index) => (
                  <div className="Heading_Box" key={index}>
                    <h3>{department.department}</h3>
                    <div className="Heading_Button">
                      <img
                        src={Edit}
                        className="Edit"
                        alt="Edit"
                        onClick={() => {
                          setShowEdit(true);
                          setData(department);
                        }}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <div className="Image_Container">
                  <img src={NoMasters} alt="No Masters" />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Department;
