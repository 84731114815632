import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import Loader from "../../assets/gif/Loader.gif";
import { useCustomer } from "../../context/CustomerProvider";
import TimeFormatter from "../../utils/gloablDateTimeFunction";
import { useAttendanceTable } from "../../hooks/useTime/attendanceTable";
const LogDetails = ({ open, handleClose, employeeId, employeeName }) => {
  const [logData, setLogData] = useState([]);
  const { customer } = useCustomer();
  const [companyLogo, setComplanyLogo] = useState("");
  const [shiftSet, setShiftSet] = useState("");
  const [recordsFound, setRecordsFound] = useState(true);
  const [loading, setLoading] = useState(false);
  const { getTimeByCountry } = useAttendanceTable();
  useEffect(() => {
    setComplanyLogo(customer?.data?.businesslogo);
  }, [customer]);

  useEffect(() => {
    if (open && employeeId) {
      setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/liveTracker/filterByEmployeeId?employeeId=${employeeId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.message === "Records retrieved successfully") {
            setLoading(false);

            setLogData(response.data.data);
            setShiftSet(response?.data?.shift);
            setRecordsFound(response.data.data.length > 0);
          } else {
            setLoading(false);
            setLogData([]);
            setRecordsFound(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("There was an error fetching the log details!", error);
          setLogData([]);
          setRecordsFound(false);
        });
    }
  }, [open, employeeId]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? "Invalid Date" : date.toLocaleDateString();
  };

  const formatSnippet = (snippet) => {
    return snippet ? `data:image/jpeg;base64,${snippet}` : "";
  };

  const checkIsLate = (inTime, shift, graceTime) => {
    // Parse the shift's start time (assuming format: "HH:mm-HH:mm")
    const [shiftStartTime] = shift.split("-")[0].split(":");
    const officeStartTime = new Date();
    officeStartTime.setHours(Number(shiftStartTime), 0, 0, 0);

    // Calculate the grace period
    const gracePeriod = Number(graceTime) * 60 * 1000; // convert grace time to milliseconds
    const gracePeriodEnd = new Date(officeStartTime.getTime() + gracePeriod);

    let userInTime;
    if (isNaN(Date.parse(inTime))) {
      // Parse in-time (assuming format: "HH:mm AM/PM")
      const [time, meridian] = inTime.split(" ");
      const [hours, minutes] = time.split(":").map(Number);
      userInTime = new Date();
      userInTime.setHours(
        meridian.toLowerCase() === "pm" && hours !== 12 ? hours + 12 : hours,
        minutes,
        0,
        0
      );
    } else {
      // If the inTime is already in date format, directly use it
      userInTime = new Date(inTime);
    }

    // Compare user in-time with grace period
    if (userInTime > gracePeriodEnd) {
      const lateTime = userInTime - gracePeriodEnd;
      const hoursLate = Math.floor(lateTime / (1000 * 60 * 60));
      const minutesLate = Math.floor(
        (lateTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const secondsLate = Math.floor((lateTime % (1000 * 60)) / 1000);

      return {
        message: `${hoursLate}hr ${minutesLate}min ${secondsLate}sec Late`,
        success: true,
      };
    } else {
      return { message: "On Time", success: false };
    }
  };

  const getBase64ImageFromURL = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.log("Error fetching image:", error);
      return null;
    }
  };

  const handleLogDownload = async () => {
    try {
      const dataForSummeryApi = logData;
      const doc3 = new jsPDF({
        unit: "mm",
        format: [220, 297],
      });

      if (companyLogo) {
        const logoImage = new Image();
        logoImage.src = await getBase64ImageFromURL(companyLogo);

        logoImage.onload = async () => {
          const maxWidth = 60;
          const maxHeight = 30;
          let logoWidth = logoImage.width;
          let logoHeight = logoImage.height;

          const aspectRatio = logoWidth / logoHeight;
          if (logoWidth > maxWidth) {
            logoWidth = maxWidth;
            logoHeight = maxWidth / aspectRatio;
          }
          if (logoHeight > maxHeight) {
            logoHeight = maxHeight;
            logoWidth = maxHeight * aspectRatio;
          }

          const pageWidth = doc3.internal.pageSize.width;
          const logoX = (pageWidth - logoWidth) / 2;
          doc3.addImage(
            await getBase64ImageFromURL(companyLogo),
            "JPEG",
            logoX,
            10,
            logoWidth,
            logoHeight
          );

          // Function to fetch the image in base64 format

          const tableColumn3 = [
            "S.No.",
            "Date",
            "In-Timing",
            "InSnippet",
            "Out-Timing",
            "Out-Snippet",
          ];
          for (const member of dataForSummeryApi) {
            if (member?.intimeOutTime[0]?.inTimeSnippet) {
              member.intimeOutTime[0].inTimeSnippet =
                await getBase64ImageFromURL(
                  member.intimeOutTime[0].inTimeSnippet
                );
            }
            if (member?.intimeOutTime[0]?.outTimeSnippet) {
              member.intimeOutTime[0].outTimeSnippet =
                await getBase64ImageFromURL(
                  member.intimeOutTime[0].outTimeSnippet
                );
            }
          }

          // Creating table rows with images
          const tableRows3 = dataForSummeryApi.map((member, index) => {
            return [
              index + 1,
              formatDate(member.inoutDate) || "--",
              customer?.data?.country && member.intimeOutTime[0]?.inTime
                ? getTimeByCountry(
                    customer?.data?.country,
                    member.intimeOutTime[0]?.inTime,
                    customer?.data?.state,
                    shiftSet
                  )?.newTimePost18Conditionally?.newTimeLate
                : "----" || "----",
              "",
              customer?.data?.country && member.intimeOutTime[0]?.outTime
                ? getTimeByCountry(
                    customer?.data?.country,
                    member.intimeOutTime[0]?.outTime,
                    customer?.data?.state,
                    shiftSet
                  )?.newTimePost18Conditionally?.newTimeLate
                : "----" || "----",
              "",
            ];
          });

          const title = `In-Out Attendance Summary of  ${dataForSummeryApi[0]?.employeeId} ${dataForSummeryApi[0]?.employeeName}`;
          const textWidth = doc3.getTextWidth(title);
          const textX = (pageWidth - textWidth) / 2;

          doc3.text(title, textX, 45);
          doc3.setTextColor(46, 46, 46);

          const totalTableWidth = 15 + 30 + 35 + 30 + 35 + 30;
          const tableLeftMargin = (pageWidth - totalTableWidth) / 2;

          autoTable(doc3, {
            head: [tableColumn3],
            body: tableRows3,
            startY: 50,
            margin: { left: tableLeftMargin, right: tableLeftMargin },
            styles: { overflow: "linebreak", cellWidth: "wrap" },
            bodyStyles: { cellPadding: { top: 5, bottom: 5, left: 2 } },
            headStyles: {
              cellPadding: { top: 5, bottom: 5, left: 2 },
              halign: "center",
            },
            columnStyles: {
              0: { cellWidth: 15, halign: "center" },
              1: { cellWidth: 30, halign: "center" },
              2: { cellWidth: 35, halign: "center" },
              3: { cellWidth: 30, halign: "center" },
              4: { cellWidth: 35, halign: "center" },
              5: { cellWidth: 30, halign: "center" },
            },
            didDrawCell: (data) => {
              if (
                data.section === "body" &&
                (data.column.index === 5 || data.column.index === 3)
              ) {
                const rowIndex = data.row.index;
                if (rowIndex >= 0 && rowIndex < dataForSummeryApi.length) {
                  const member = dataForSummeryApi[rowIndex];
                  const imageBase64 =
                    data.column.index === 3
                      ? member.intimeOutTime[0]?.inTimeSnippet
                      : member.intimeOutTime[0]?.outTimeSnippet;

                  if (imageBase64) {
                    try {
                      doc3.addImage(
                        imageBase64,
                        "JPEG",
                        data.cell.x + (data.cell.width - 12) / 2,
                        data.cell.y + (data.cell.height - 12) / 2,
                        12,
                        12
                      );
                    } catch (error) {
                      console.log("Error adding image:", error);
                    }
                  } else {
                    doc3.autoTableText(
                      "----",
                      data.cell.x + data.cell.width / 2,
                      data.cell.y + data.cell.height / 2 + 2,
                      { halign: "center", valign: "middle" }
                    );
                  }
                }
              }
            },
            horizontalPageBreakRepeat: true,
          });

          doc3.save(
            `${dataForSummeryApi[0]?.employeeId}_${dataForSummeryApi[0]?.employeeName}_attendance.pdf`
          );
        };
        logoImage.onerror = () => {
          console.error(
            "Failed to load the logo image from the base64 string."
          );
        };
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const tableHeading = [
    { id: 1, name: "S.NO" },
    { id: 2, name: "Date" },
    { id: 3, name: "Employee ID" },
    { id: 4, name: "Employee Name" },
    { id: 5, name: "Shift Timing" },
    { id: 6, name: "In-Timing" },
    { id: 7, name: "Snippet" },
    { id: 8, name: "Out-Timing" },
    { id: 9, name: "Snippet" },
  ];
  function getLastOutTime(data) {
    console.log(data)
    const validOutTimes = data.filter(item => item.outTime !== null && item.outTime !== undefined);

    validOutTimes.sort((a, b) => new Date(b.outTime) - new Date(a.outTime));

    if (validOutTimes.length > 0) {
      console.log({
        outTime: validOutTimes[0].outTime,
        outTimeSnippet: validOutTimes[0].outTimeSnippet
      })
        return {
            outTime: validOutTimes[0].outTime,
            outTimeSnippet: validOutTimes[0].outTimeSnippet
        };
    }

    return null;
}
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box className="attendanceTable attendanceTableLog">
        {loading ? (
          <div className="logDetailsLoader">
            <img src={Loader} alt="Loading" />
          </div>
        ) : (
          <>
            <Box className="list ListlogDetails">
              {recordsFound ? (
                <>
                  <table>
                    <thead>
                      <tr className="tableAttendanceHeading">
                        {tableHeading.map((h) => (
                          <th key={h.id}>{h.name}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {logData.map((data, index) => (
                        <tr key={index} className="tableDataName">
                          <td>{index + 1}</td>
                          <td>{formatDate(data.inoutDate)}</td>
                          <td>{data.employeeId}</td>
                          <td>{data.employeeName}</td>
                          <td>{shiftSet}</td>
                          <td>
                            {customer.data.country &&
                            data.intimeOutTime[0]?.inTime ? (
                              <TimeFormatter
                                state={customer.data.state}
                                country={customer.data.country}
                                time={data.intimeOutTime[0]?.inTime}
                              />
                            ) : (
                              "NA"
                            )}
                          </td>
                          <td>
                            {data.intimeOutTime[0]?.inTimeSnippet ? (
                              <img
                                style={{ height: "40px", width: "40px" }}
                                src={data.intimeOutTime[0]?.inTimeSnippet}
                                alt="in-timing-snippet"
                              />
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td>
                            {customer.data.country &&
                            getLastOutTime(data.intimeOutTime)?.outTime ? (
                              <TimeFormatter
                                state={customer.data.state}
                                country={customer.data.country}
                                time={getLastOutTime(data.intimeOutTime)?.outTime}
                              />
                            ) : (
                              "NA"
                            )}
                          </td>
                          <td>
                            {getLastOutTime(data.intimeOutTime)?.outTimeSnippet ? (
                              <img
                                style={{ height: "40px", width: "40px" }}
                                src={getLastOutTime(data.intimeOutTime)?.outTimeSnippet}
                                alt="out-timing-snippet"
                              />
                            ) : (
                              "N/A"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <Box className="noRecordsFound">
                  <p
                    style={{
                      height: "50%",
                      width: "50%",
                      top: "50%",
                      left: "25%",
                      textAlign: "center",
                      position: "absolute",
                    }}
                  >
                    <h3> No records found for the employee ID.</h3>
                  </p>
                </Box>
              )}
            </Box>
            {logData.length > 0 && (
              <Box
                className="button_download"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button onClick={handleLogDownload} className="downloadBtn">
                  <span>Download</span>
                </button>
              </Box>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default LogDetails;
