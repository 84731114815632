import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/AdminLayout/Layout";
import axios from "axios";
import { useModel } from "../../hooks/useModel/useModel";
import AutoReports from "../../model/autoReports/AutoReports";
import ShimmerAutoReport from "../../skeleton/ShimmerAutoReport/ShimmerAutoReport";

const ReportsAutomation = () => {
  const { open, handleClose, handleOpen } = useModel();
  const [shift, setShift] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [update, setUpdate] = useState(false);
  const handleOpenModel = (type, data=null) => {
   
    if (type == "update") {
      console.log(data)
      setSelectedData(data);
      setUpdate(true);
      handleOpen();
      return;
    } else {
      setSelectedData(data);
      setUpdate(false);
      handleOpen();
    }
  };
  const getShift = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/masters/shiftname/get`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      if (data?.status_code === 200) {
        setShift(data?.shiftnames);
      }
    } catch (e) {
      console.log(e);
      setError("shift is noyt found");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getShift();
  }, []);

  const handleTime = (time) => {
    if (!time || typeof time !== "string") return ""; // Handle invalid input
  
    const [hours, minutes] = time.split(":").map(Number); // Extract hours & minutes
    const ampm = hours >= 12 ? "PM" : "AM"; // Determine AM/PM
  
    const formattedHours = hours % 12 || 12; // Convert 24-hour to 12-hour format
  
    return `${formattedHours}.${minutes.toString().padStart(2, "0")}${ampm}`;
  };

  return (
    <Layout>
      <section className="liveTracker">
        <div
          className="one heading_Att_reports"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "70px",
          }}
        >
          <h3>Reports Automation</h3>
        </div>
        <div className="commonBorderForAll">
          {error ? (
            <div className="logDetailsLoader">
              <h1>No Reports Found</h1>
            </div>
          ) : (
            <>
              <div className="heading ">
                <h1>Set Report Generation Time for your shifts</h1>
              </div>

              <div className="listOfAttendanceEmployee">
                {
                loading ?
                [...Array(3)].map((_, index) => <ShimmerAutoReport key={index} />)
                :
                shift?.length > 0 &&
                  shift?.map((autoReport, i) => (
                    <div className="autoReports" key={autoReport?._id}>
                      <div className="autoReports_child">
                        <div className="autoReports-one">
                          <div className="autoReports-one-child">
                            <h3>{autoReport?.shiftid}</h3>
                            <p>{autoReport?.location}</p>
                            <p>
                              {autoReport?.fromtiming}
                              {"-"}
                              {autoReport?.fromtiming}
                            </p>
                            <p>GT - {autoReport?.gracetime} Min</p>
                          </div>
                        </div>
                        <div className="autoReports-two">
                          {!autoReport?.email ? (
                            <p className="autoReports-two_tag">
                              Auto report generation is not yet set for this
                              shift.
                            </p>
                          ) : (
                            <p>
                              Everyday reports will be sent to{" "}
                              <span style={{ textDecoration: "underline" }}>
                              {autoReport?.email}
                              </span>{" "}
                              by{" "}
                              <span style={{ textDecoration: "underline" }}>
                               {handleTime(autoReport?.time)}
                              </span>
                              
                            </p>
                          )}
                        </div>
                        <div
                          className="autoReports-three"
                          onClick={() => {
                            autoReport?.email
                             ? handleOpenModel("update", autoReport)
                              :handleOpenModel("submit", autoReport)
                              
                          }}
                        >
                          {autoReport?.email  ? <p>Change</p> : <p>Set Auto Report</p>}
                        </div>
                      </div>
                    </div>
                  ))
                  
                  
                  }
              </div>
            </>
          )}
        </div>
      </section>
      <AutoReports
        open={open}
        handleClose={handleClose}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        update={update}
        setUpdate={setUpdate}
        getShift={getShift}
      />
    </Layout>
  );
};

export default ReportsAutomation;
