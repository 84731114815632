import React, { useState, useEffect } from "react";
import Face from "../../../assets/images/face.svg";
import backGroundImage from "../../../assets/images/from_back.svg";
import facesenseHeading from "../../../assets/images/faceHeading.svg";
import backArrow from "../../../assets/images/backArrow.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../../../assets/gif/Loader.gif";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import Logo from "../../../assets/images/Okulr_Logo.png";

const Forgetpassword = () => {
  const [buttontext, setButtonText] = useState("Get OTP");
  const [isdisabled, setIsDisabled] = useState(true);
  const [generatedotp, setGeneratedOTP] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(59);
  const [isotpdisabled, setIsOTPDisabled] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [showloader, setShowLoader] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    otp: "",
  });

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setError(false);
    setMessage("");
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailBlur = () => {
    if (!validateEmail(formData.email)) {
      setMessage("Invalid Email");
      setError(true);
    } else {
      setMessage("");
      setError(false);
    }
  };

  const handleAPI = async () => {
    if (!error && formData.email !== "") {
      setShowLoader(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/forgotpassword`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status_code === 200) {
        setMessage(response.data.message);
        setGeneratedOTP(response.data.otp);
        setShowLoader(false);
        setButtonText("Verify");
        setIsDisabled(false);
      } else {
        setError(true);
        setShowLoader(false);
        setMessage(response.data.message);
      }
    }
  };
  const storeEmailInCookie = (email) => {
    const encryptedEmail = CryptoJS.AES.encrypt(
      email,
      "your-secret-key"
    ).toString();

    Cookies.set("forgotPasswordEmail", encryptedEmail, { expires: 1 }); // Expires in 1 day
  };
  const handleClick = () => {
    if (buttontext === "Get OTP") {
      handleAPI();
    } else if (buttontext === "Verify") {
      if (!error && formData.email !== "" && formData.otp !== "") {
        if (formData.otp === generatedotp) {
          storeEmailInCookie(formData.email);
          navigate("/changepassword", {
            state: { email: formData.email },
          });
        } else {
          setError(true);
          setMessage("Incorrect OTP");
        }
      }
    }
  };
  useEffect(() => {
    if (timeRemaining > 0 && buttontext === "Verify") {
      const timer = setTimeout(() => {
        setTimeRemaining((prev) => prev - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (timeRemaining === 0) {
      setIsOTPDisabled(true);
    }
  }, [timeRemaining, buttontext]);

  const handleResendOTP = () => {
    setMessage("");
    setError(false);
    setTimeRemaining(59);
    setIsOTPDisabled(false);
    handleAPI();
  };

  return (
    <>
      {showloader && (
        <>
          <div className="Loader">
            <img src={Loader} alt="Loading" />
          </div>
        </>
      )}
      <header>
        <div className="Header_Component">
          <img src={Logo} alt="Okulr Techminds" />
        </div>
      </header>
      <div className="Main_Background">
        <div className="Left_Section">
          <div className="Left_Section_Contents">
            <img src={Face} alt="Facesense" />
            <h1>Your Face is Your ID</h1>
            <p>The new era has arrived !</p>
          </div>
        </div>
        <div className="Right_Section">
          <img src={backGroundImage} className="Form_Background" alt="Back" />
          <div className="Right_Section_Contents">
            <img src={facesenseHeading} alt="Facesense" />
            <div className="Input_Box">
              <h2>Oops! Forgot Password?</h2>
              <h4>Don’t worry we will help you.</h4>
              <div className="Input_Field">
                <div className="Label">Email</div>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  onBlur={handleEmailBlur}
                  required
                  disabled={!isdisabled}
                />
              </div>
              <div className="Input_Field">
                <div className="Label disable">Enter OTP</div>
                <input
                  type="number"
                  name="otp"
                  value={formData.otp}
                  onChange={handleInputChange}
                  onBlur={handleEmailBlur}
                  required
                  disabled={isdisabled}
                />
                {!isdisabled && (
                  <>
                    <div className="OTP_Box">
                      <h5
                        style={{
                          visibility: isotpdisabled ? "hidden" : "visible",
                        }}
                      >
                        Time Remaining : <strong>{timeRemaining}sec</strong>
                      </h5>
                      <h6
                        onClick={isotpdisabled ? handleResendOTP : null}
                        style={{
                          cursor: isotpdisabled ? "pointer" : "default",
                          color: isotpdisabled ? "#0a3b66" : "#d9d9d9",
                          fontWeight: isotpdisabled ? "700" : "500",
                        }}
                      >
                        Resend OTP
                      </h6>
                    </div>
                  </>
                )}
              </div>

              <div
                className="Email_Sent"
                style={{ color: error ? "#eb0100" : "#01a601" }}
              >
                {message}
              </div>
            </div>
            <button type="button" onClick={handleClick}>
              {buttontext}
            </button>
            <img
              className="Back_Button"
              onClick={() => {
                navigate("/login");
              }}
              src={backArrow}
              alt="Back Button"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgetpassword;
