import React, { useState, useEffect } from "react";
import Edit from "../../../../assets/masters/Edit.svg";
import axios from "axios";
import NoMasters from "../../../../assets/masters/NoMasters.svg";
import { Country, State, City } from "country-state-city";
import Dropdown from "../../../../assets/masters/Dropdown.svg";
import MastersShimmer from "../../../../skeleton/Masters/MastersShimmer";
import Loader from "../../../../assets/masters/Loader.gif";
import { BaseURL } from "../../../../BaseURL";


const Location = ({ setChange, change }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [nostate, setNoState] = useState(false);
  const [nocity, setNoCity] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [showedit, setShowEdit] = useState(false);
  const [data, setData] = useState({});
  const [locations, setLocations] = useState([]);
  const [formData, setFormData] = useState({
    country: "",
    pincode: "",
    state: "",
    city: "",
    addressline1: "",
    addressline2: "",
  });
  const [buttontext, setButtonText] = useState("Submit");
  const [inputErrors, setInputErrors] = useState({
    country: false,
    pincode: false,
    state: false,
    city: false,
    addressline1: false,
    addressline2: false,
  });
  const [loader, setLoader] = useState(false);
  const [submitloader, setSubmitLoader] = useState(false);
  
  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    getLocations();
  }, []);
  useEffect(() => {
    const countryList = Country.getAllCountries().map((country) => ({
      name: country.name,
      code: country.isoCode,
    }));
    setCountries(countryList);  
  }, []);
  useEffect(() => {
    if (selectedCountry) {
      const stateList = State.getStatesOfCountry(selectedCountry).map(
        (state) => ({
          name: state.name,
          code: state.isoCode,
        })
      );
      if (stateList?.length > 0) {
        setStates(stateList);
        setNoState(false);
        setNoCity(false);
      } else {
        setNoCity(true);
        setNoState(true);
        setFormData((prevData) => ({
          ...prevData,
          state: "",
          city: "",
        }));
      }
    }
  }, [selectedCountry, formData.country]);
  useEffect(() => {
    if (selectedState) {
      const cityList = City.getCitiesOfState(
        selectedCountry,
        selectedState
      ).map((city) => ({
        name: city.name,
      }));
      if (cityList?.length > 0) {
        setCities(cityList);
        setNoCity(false);
      } else {
        setNoCity(true);
        setFormData((prevData) => ({
          ...prevData,
          city: "",
        }));
      }
    }
  }, [selectedState, selectedCountry, formData.state]);

  const handleCountryChange = (countryCode) => {
    const selectedCountry = countries.find(
      (country) => country.code === countryCode
    );

    setFormData((prevData) => ({
      ...prevData,
      country: selectedCountry?.name || "",
    }));

    setSelectedCountry(countryCode);
    setInputErrors((prevData) => ({
      ...prevData,
      country: false,
    }));

    setSelectedState("");
    setFormData((prevData) => ({
      ...prevData,
      city: "",
    }));
  };

  const handleStateChange = (stateCode) => {
    const selectedState = states.find((state) => state.code === stateCode);
    setFormData((prevData) => ({
      ...prevData,
      state: selectedState?.name || "",
    }));
    setSelectedState(stateCode);
    setInputErrors((prevData) => ({
      ...prevData,
      state: false,
    }));
    setFormData((prevData) => ({
      ...prevData,
      city: "",
    }));
  };

  const getLocations = async () => {
    try {
      setLoader(true);

      const response = await axios.get(`${BaseURL}/masters/location/get`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      if (response.data.status_code === 200) {
        setLocations(response.data.locations);
      }
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setInputErrors({ ...inputErrors, [name]: false });
  };

  const handleBlur = async (e) => {
    const { name, value } = e.target;
    if (!value.trim()) {
      setInputErrors({ ...inputErrors, [name]: true });
    }
  };

  const handleClick = async () => {
    const errors = {
      country: !formData.country.trim(),
      pincode: !formData.pincode.trim(),
      state: !formData.state.trim(),
      city: !formData.city.trim(),
      addressline1: !formData.addressline1.trim(),
      addressline2: !formData.addressline2.trim(),
    };

    setInputErrors(errors);

    const hasErrors = Object.values(errors).some((error) => error);
    if (!hasErrors) {
      if (buttontext === "Submit") {
        try {
          setSubmitLoader(true);
          await axios.post(`${BaseURL}/masters/location/add`, formData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
          });
          setFormData((prevData) => ({
            ...prevData,
            country: "",
            pincode: "",
            state: "",
            city: "",
            addressline1: "",
            addressline2: "",
          }));
          getLocations();
          setSelectedCountry("");
          setSelectedState("");
        } catch (err) {
          console.log(err);
        } finally {
          setSubmitLoader(false);
        }
      } else if (buttontext === "Update") {
        try {
          setSubmitLoader(true);
          await axios.put(`${BaseURL}/masters/location/edit`, formData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
          });
          setFormData((prevData) => ({
            ...prevData,
            country: "",
            pincode: "",
            state: "",
            city: "",
            addressline1: "",
            addressline2: "",
          }));
          setSelectedCountry("");
          setSelectedState("");
          getLocations();
          setChange(!change);
          setButtonText("Submit");
        } catch (err) {
          console.log(err);
        } finally {
          setSubmitLoader(false);
        }
      }
    }
  };
  const handleEdit = (location) => {
    setButtonText("Update");
    setFormData(location);
    setShowEdit(false);
  };

  return (
    <>
      {submitloader && (
        <>
          <div className="Loader main">
            <img src={Loader} alt="Loading" />
          </div>
        </>
      )}
      {showedit && (
        <>
          <div className="Loader">
            <div className="Popup_Box confirm">
              <div className="Popup_Text">
                <h1>Are you Sure?</h1>
                <p>
                  You want to edit the master details. Employees linked with
                  this details will get updated.
                </p>
              </div>
              <div className="Popup_Button confirm">
                <h1
                  onClick={() => {
                    handleEdit(data);
                  }}
                >
                  Edit
                </h1>
                <button
                  onClick={() => {
                    setShowEdit(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="Main_Heading">
        <div className="Heading_Section">
          <h2>Add Location</h2>
          <div className="Input_Boxes">
            <div className="Input_Field">
              <div className="Custom_Select">
                <select
                  name="country"
                  value={selectedCountry}
                  onChange={(e) => {
                    handleCountryChange(e.target.value);
                  }}
                  onBlur={handleBlur}
                  style={{
                    borderColor: inputErrors.country ? "red" : "#ccc",
                  }}
                >
                  <option value="">{formData?.country}</option>
                  {countries.map((country, index) => (
                    <option key={index} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </select>
                <img
                  src={Dropdown}
                  alt="Dropdown arrow"
                  className="Dropdown_Arrow"
                />
                <div className="Label">Country</div>
              </div>
            </div>

            <div className="Input_Field">
              <div className="Custom_Select">
                <select
                  disabled={nostate}
                  name="state"
                  value={nostate ? formData.state : selectedState}
                  onChange={(e) => {
                    handleStateChange(e.target.value);
                  }}
                  onBlur={handleBlur}
                  style={{
                    borderColor: inputErrors.state ? "red" : "#ccc",
                  }}
                >
                  {nostate && (
                    <option value={formData.state}>{formData.state}</option>
                  )}
                  <option value=""></option>
                  {states.map((state, index) => (
                    <option key={index} value={state.code}>
                      {state.name}
                    </option>
                  ))}
                </select>
                <img
                  src={Dropdown}
                  alt="Dropdown arrow"
                  className="Dropdown_Arrow"
                />
                <div className="Label">State</div>
              </div>
            </div>

            <div className="Input_Field">
              <div className="Custom_Select">
                <select
                  disabled={nocity}
                  name="city"
                  value={formData.city}
                  onChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      city: e.target.value,
                    }));
                    handleInputChange(e);
                    setInputErrors((prevData) => ({
                      ...prevData,
                      city: false,
                    }));
                  }}
                  onBlur={handleBlur}
                  style={{
                    borderColor: inputErrors.city ? "red" : "#ccc",
                  }}
                >
                  {nocity && (
                    <option value={formData.city}>{formData.city}</option>
                  )}
                  <option value=""></option>
                  {cities.map((city, index) => (
                    <option key={index} value={city.name}>
                      {city.name}
                    </option>
                  ))}
                </select>
                <img
                  src={Dropdown}
                  alt="Dropdown arrow"
                  className="Dropdown_Arrow"
                />
                <div className="Label">City</div>
              </div>
            </div>
            <div className="Input_Field">
              <div className="Label">Pincode</div>
              <input
                type="text"
                name="pincode"
                maxLength={10}
                value={formData.pincode}
                onChange={handleInputChange}
                onBlur={handleBlur}
                style={{
                  borderColor: inputErrors.pincode ? "red" : "#ccc",
                }}
              />
            </div>
            <div className="Input_Field">
              <div className="Label">Address Line 1</div>
              <input
                type="text"
                name="addressline1"
                value={formData.addressline1}
                onChange={handleInputChange}
                onBlur={handleBlur}
                style={{
                  borderColor: inputErrors.addressline1 ? "red" : "#ccc",
                }}
              />
            </div>
            <div className="Input_Field">
              <div className="Label">Address Line 2</div>
              <input
                type="text"
                name="addressline2"
                value={formData.addressline2}
                onChange={handleInputChange}
                onBlur={handleBlur}
                style={{
                  borderColor: inputErrors.addressline2 ? "red" : "#ccc",
                }}
              />
            </div>
          </div>
          <div className="Heading_Submit">
            <button onClick={handleClick}>{buttontext}</button>
          </div>
        </div>
        <div className="Heading_Details">
          <h2>Location Details</h2>
          {loader ? (
            <MastersShimmer height={"50px"} />
          ) : (
            <div className="All_Headings">
              {locations.length > 0 ? (
                locations.map((location, index) => (
                  <div className="Heading_Box" key={index}>
                    <h3>
                      {location.city} - {location.pincode}
                    </h3>
                    <h4>
                      {location.country}, {location.state}, {location.city},{" "}
                      {location.pincode}
                    </h4>
                    <h4>
                      {location.addressline1} {location.addressline2}
                    </h4>

                    <div className="Heading_Button">
                      {/* <img
                      src={Delete}
                      className="Delete"
                      alt="Delete"
                      onClick={() => {
                        setShowDelete(true);
                        setFormData(location);
                      }}
                    /> */}
                      <img
                        src={Edit}
                        className="Edit"
                        alt="Edit"
                        onClick={() => {
                          setShowEdit(true);
                          setData(location);
                          const selectedCountry1 = countries.find(
                            (country) => country.name === location.country
                          );
                          if (selectedCountry1) {
                            setSelectedCountry(selectedCountry1);
                            handleCountryChange(selectedCountry1);
                          }
                          console.log(location);
                        }}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <div className="Image_Container">
                  <img src={NoMasters} alt="No Masters" />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Location;
