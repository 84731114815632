import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import Loader from "../../assets/gif/Loader.gif";
import { useCustomer } from "../../context/CustomerProvider";
import { useAttendanceTable } from "../../hooks/useTime/attendanceTable";
import { useEmployee } from "../../context/EmpProvider";

const AttendanceDateWise = ({ open, handleClose, filterType }) => {
  const [logData, setLogData] = useState([]);
  const { customer } = useCustomer();
  const [companyLogo, setComplanyLogo] = useState("");
  const { getTimeByCountry } = useAttendanceTable();
  useEffect(() => {
    setComplanyLogo(customer?.data?.businesslogo);
  }, [customer]);
  const [recordsFound, setRecordsFound] = useState(true);
  const [loading, setLoading] = useState(false);
  const { selectedDate } = useEmployee();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? "Invalid Date" : date.toLocaleDateString();
  };
  const getBase64ImageFromURL = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.log("Error fetching image:", error);
      return null;
    }
  };

  const generateReport = async () => {
    try {
      const dataForSummeryApi = logData;

      const doc3 = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [297, 210],
      });

      const logoImage = new Image();
      logoImage.src = await getBase64ImageFromURL(companyLogo);

      logoImage.onload = async () => {
        const originalWidth = logoImage.width;
        const originalHeight = logoImage.height;

        // Set maximum dimensions
        const maxWidth = 70; // Adjust maximum width
        const maxHeight = 35; // Adjust maximum height

        let logoWidth = originalWidth;
        let logoHeight = originalHeight;

        // Calculate aspect ratio and adjust width/height to fit within max dimensions
        const aspectRatio = originalWidth / originalHeight;

        if (logoWidth > maxWidth) {
          logoWidth = maxWidth;
          logoHeight = maxWidth / aspectRatio;
        }

        if (logoHeight > maxHeight) {
          logoHeight = maxHeight;
          logoWidth = maxHeight * aspectRatio;
        }

        // Add the logo to the PDF with the adjusted size
        doc3.addImage(
          await getBase64ImageFromURL(companyLogo),
          "JPEG",
          10,
          10,
          logoWidth,
          logoHeight
        );

        // Define table structure
        const tableColumn3 = [
          "S.No.",
          "Date",
          "Employee ID",
          "Employee Name",
          "Shift Timing",
          "In-Timing",
          "InSnippet",
          "Out-Timing",
          "Out-Snippet",
        ];

        // Convert images for each row
        for (const member of dataForSummeryApi) {
          if (member.inTimeSnippet) {
            member.inTimeSnippet = await getBase64ImageFromURL(
              member.inTimeSnippet
            );
          }
          if (member.outTimeSnippet) {
            member.outTimeSnippet = await getBase64ImageFromURL(
              member.outTimeSnippet
            );
          }
        }

        // Prepare table rows
        const tableRows3 = dataForSummeryApi.map((member, index) => [
          index + 1,
          formatDate(member.date) || "----",
          member.employeeId || "----",
          member.employeeName || "----",
          member.shiftTiming || "----",
          customer?.data?.country && member?.firstInTime
            ? getTimeByCountry(
                customer?.data?.country,
                member?.firstInTime,
                customer?.data?.state,
                member?.shiftTiming
              )?.newTimePost18Conditionally?.newTimeLate
            : "----" || "----",
          "", // Placeholder for inTimeSnippet
          customer.data.country &&
          member.lastOutTime &&
          member.lastOutTime !== "N/A"
            ? getTimeByCountry(
                customer?.data?.country,
                member?.lastOutTime,
                customer?.data?.state,
                member?.shiftTiming
              )?.newTimePost18Conditionally?.newTimeLate
            : "----" || "----",
          "", // Placeholder for outTimeSnippet
        ]);
        const pageWidth = doc3.internal.pageSize.width;
        const title = "In-Out Time Attendance Summary";
        const textWidth = doc3.getTextWidth(title);
        const textX = (pageWidth - textWidth) / 2;

        doc3.text(title, textX, 40);
        doc3.setTextColor(46, 46, 46);
        autoTable(doc3, {
          head: [tableColumn3],
          body: tableRows3,
          startY: 50,
          styles: { overflow: "linebreak", cellWidth: "wrap" },
          bodyStyles: { cellPadding: { top: 7, bottom: 7, left: 2 } },
          headStyles: { cellPadding: { top: 7, bottom: 7, left: 2 } },
          columnStyles: {
            0: { cellWidth: 15 },
            1: { cellWidth: 30 },
            2: { cellWidth: 35 },
            3: { cellWidth: 35 },
            4: { cellWidth: 25 },
            5: { cellWidth: 25 },
            6: { cellWidth: 30 },
            7: { cellWidth: 25 },
            8: { cellWidth: 30 },
          },
          didDrawCell: (data) => {
            if (
              data.section === "body" &&
              (data.column.index === 6 || data.column.index === 8)
            ) {
              const rowIndex = data.row.index;
              const member = dataForSummeryApi[rowIndex];
              const imageBase64 =
                data.column.index === 6
                  ? member?.inTimeSnippet
                  : member?.outTimeSnippet;

              if (imageBase64) {
                try {
                  doc3.addImage(
                    imageBase64,
                    "JPEG",
                    data.cell.x + 2,
                    data.cell.y + 2,
                    15,
                    15
                  );
                } catch (error) {
                  console.log("Error adding image:", error);
                }
              } else {
                doc3.autoTableText("----", data.cell.x + 3, data.cell.y + 4);
              }
            }
          },
        });
       
        doc3.save(`attendance.pdf`);
      };
    } catch (error) {
      console.log("Error generating PDF:", error);
    }
  };

  const downloadReport = async (filterType) => {
    try {
      if (!filterType) {
        // console.log("No filterType provided");
        return;
      }

      setLoading(true);

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/liveTracker/filter-livetracker`,
        {
          params: {
            filterType: filterType,
            startDateFilter: selectedDate?.startDate,
            endDateFilter: selectedDate?.endDate,
          },
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      if (response?.statusText === "OK") {
        setRecordsFound(response.data.length > 0);
        setLogData(response?.data);
      }
    } catch (error) {
      setLoading(false);
      setRecordsFound(false);
      console.error("Error fetching attendance reports:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open && filterType) {
      //   console.log("checkFilterType", filterType);
      downloadReport(filterType);
    }
  }, [open, filterType]);

  const tableHeading = [
    { id: 1, name: "S.NO" },
    { id: 2, name: "Date" },
    { id: 3, name: "Employee ID" },
    { id: 4, name: "Employee Name" },
    { id: 5, name: "Shift Timing" },
    { id: 6, name: "In-Timing" },
    { id: 7, name: "In-Snippet" },
    { id: 8, name: "Out-Timing" },
    { id: 9, name: "Out-Snippet" },
  ];

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box className="attendanceTable attendanceTableLog">
        {loading ? (
          <div className="logDetailsLoader">
            <img src={Loader} alt="Loading" />
          </div>
        ) : (
          <>
            <Box className="list ListlogDetails">
              {recordsFound ? (
                <>
                  <table>
                    <thead>
                      <tr className="tableAttendanceHeading">
                        {tableHeading.map((h) => (
                          <th key={h.id}>{h.name}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {logData.map((data, index) => (
                        <tr key={index} className="tableDataName">
                          <td>{index + 1}</td>

                          <td>{formatDate(data.date)}</td>
                          <td>{data.employeeId}</td>
                          <td>{data.employeeName}</td>
                          <td>{data?.shiftTiming}</td>
                          <td>
                            {customer?.data?.country && data?.firstInTime
                              ? getTimeByCountry(
                                  customer?.data?.country,
                                  data?.firstInTime,
                                  customer?.data?.state,
                                  data?.shiftTiming
                                )?.newTimePost18Conditionally?.newTimeLate
                              : "NA" || "--"}
                          </td>
                          <td>
                            {data.inTimeSnippet ? (
                              <img
                                 style={{ height: "40px", width: "40px" }}
                                src={data.inTimeSnippet}
                                alt="in-timing-snippet"
                              />
                            ) : (
                              "N/A"
                            )}
                          </td>

                          <td>
                            {customer.data.country &&
                            data.lastOutTime &&
                            data.lastOutTime !== "N/A"
                              ? getTimeByCountry(
                                  customer?.data?.country,
                                  data?.lastOutTime,
                                  customer?.data?.state,
                                  data?.shiftTiming
                                )?.newTime
                              : "NA" || "--"}
                          </td>

                          <td>
                            {data.outTimeSnippet ? (
                              <img
                                style={{ height: "40px", width: "40px" }}
                                src={data.inTimeSnippet}
                                alt="out-timing-snippet"
                              />
                            ) : (
                              "N/A"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <Box className="noRecordsFound">
                  <p
                    style={{
                      height: "50%",
                      width: "50%",
                      top: "50%",
                      left: "25%",
                      textAlign: "center",
                      position: "absolute",
                    }}
                  >
                    <h3> No records found.</h3>
                  </p>
                </Box>
              )}
            </Box>
            {logData.length > 0 && (
              <Box
                className="button_download"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button onClick={generateReport} className="downloadBtn">
                  <span>Download</span>
                </button>
              </Box>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default AttendanceDateWise;
