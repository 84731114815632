import React from "react";

const LiveTrackerSkeleton = () => {
  return (
    <div className="skeleton-container-liveTracker">
      {Array.from({ length: 15 }).map((_, index) => (
        <div key={index} className="skeleton-detail-liveTracker"></div>
      ))}
    </div>
  );
};

export default LiveTrackerSkeleton;
