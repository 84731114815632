import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Spreadsheet from "react-spreadsheet";
import { useNavigate, useLocation } from "react-router-dom";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid"; // ✅ Import UUID for requestId
import { BaseURL } from "../../../BaseURL";
import axios from "axios";
import UploadPopup from "./uploadPopup";
function SpreadsheetComponent() {
  const spreadsheetRef = useRef(null); // ✅ Define the ref
  const navigate = useNavigate();
  const location = useLocation();
  const [batch, setBatch] = useState(location.state?.batch || []);
  const prevBatchRef = useRef(batch);

  console.log(batch,"batch")
  const [isUploading, setIsUploading] = useState(false); // ✅ Uploading state
  const [uploadProgress, setUploadProgress] = useState(0); // ✅ Progress state
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    if (isUploading) {
      setUploadProgress(0); // Reset progress when upload starts

      const interval = setInterval(() => {
        setUploadProgress((prev) => {
          if (prev >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prev + Math.random() * 10; // Increase progress randomly
        });
      }, 5000); // Update every 500ms

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [isUploading]);

  const columnLabels = [
    "Emp. ID",
    "First Name",
    "Last Name",
    "D.O.B (DD/MM/YY)",
    "Gender",
    "Email (Personal)",
    "Email (Official)",
    "Mobile No.",
    "D.O.J (DD/MM/YY)",
    "Proof Id",
    "Proof Id No.",
    "Address",
    "Pincode",
    "City",
    "State",
    "Country",
  ];

  const formatBatchData = () =>
    batch.map((emp) => [
      { value: emp.employeeId },
      { value: emp.firstName },
      { value: emp.lastName },
      { value: emp.dob?.toISOString().split("T")[0] || "" },
      { value: emp.gender },
      { value: emp.emailPersonal },
      { value: emp.emailOfficial },
      { value: emp.mobileNo },
      {
        value: emp.joiningDate
          ? new Date(emp.joiningDate).toISOString().split("T")[0]
          : "",
      },
      { value: emp.proofId },
      { value: emp.proofIdNo },
      { value: emp.address },
      { value: emp.pincode },
      { value: emp.city },
      { value: emp.state },
      { value: emp.country || "India" },
    ]);

  const initialRows = 30;

  // 🔹 Load Data from sessionStorage or Batch
  const loadInitialData = () => {
    const storedData = sessionStorage.getItem("spreadsheetData");
    if (storedData) return JSON.parse(storedData);
    return batch.length > 0
      ? formatBatchData()
      : Array.from({ length: initialRows }, createEmptyRow);
  };

  const createEmptyRow = () =>
    columnLabels.map((label) =>
      label === "Country" ? { value: "" } : { value: "" }
    );

  const [spreadsheetData, setSpreadsheetData] = useState(loadInitialData);

  useEffect(() => {
    sessionStorage.setItem("spreadsheetData", JSON.stringify(spreadsheetData));
  }, [spreadsheetData]);


  useEffect(() => {
    if (!_.isEqual(prevBatchRef.current, location.state?.batch)) {
      setBatch(location.state?.batch || []);
      prevBatchRef.current = location.state?.batch || [];
    }
  }, [location.state?.batch]); // ✅ Only runs when location.state.batch changes
  

  const [rowLabels, setRowLabels] = useState(
    Array.from({ length: initialRows }, (_, i) => `${i + 1}`)
  );

  const [pincodeUpdates, setPincodeUpdates] = useState({}); // 🔥 Store only pincode changes
  const debounceRef = useRef(null);

 const fetchLocationDetails = async (pincode) => {
  try {
    const response = await axios.get(
      `https://api.postalpincode.in/pincode/${pincode}`
    );

    if (response.data && response.data[0].Status === "Success") {
      const postOffice = response.data[0].PostOffice?.[0];

      return {
        city: postOffice?.District?.trim() || "",
        state: postOffice?.State?.trim() || "",
        country: postOffice?.Country?.trim() || "India",
      };
    }
  } catch (error) {
    console.error("Error fetching location details:", error);
  }

  return { city: "", state: "", country: "India" };
};


useEffect(() => {
  if (_.isEmpty(pincodeUpdates)) return;

  if (debounceRef.current) {
    clearTimeout(debounceRef.current);
  }

  debounceRef.current = setTimeout(async () => {
    const results = await Promise.all(
      Object.entries(pincodeUpdates).map(async ([rowIndex, pincode]) => {
        const { city, state, country } = await fetchLocationDetails(pincode);
        return { rowIndex: Number(rowIndex), city, state, country };
      })
    );

    setSpreadsheetData((prevData) => {
      let hasChanges = false;
    
      const newData = prevData.map((row, index) => {
        if (!Array.isArray(row)) {
          console.error(`🚨 Row at index ${index} is not an array:`, row);
          return Array(16).fill({ value: "" }); // Ensure row has proper structure
        }
    
        const update = results.find((r) => r.rowIndex === index);
        if (!update) return row;
    
        let updatedRow = row.map((cell, cellIndex) => {
          if (!cell || typeof cell !== "object") {
            console.error(`🚨 Cell at row ${index}, column ${cellIndex} is undefined or not an object`);
            return { value: "" }; // Default empty cell
          }
    
          let newValue =
            cellIndex === 13 ? update.city :
            cellIndex === 14 ? update.state :
            cellIndex === 15 ? update.country :
            cell.value;
    
          if (cell.value !== newValue) {
            hasChanges = true;
            return { ...cell, value: newValue };
          }
          return cell;
        });
    
        return updatedRow;
      });
    
      return hasChanges ? newData : prevData;
    });
    
    

    setPincodeUpdates({}); // 🛑 Ensure updates reset after processing

  }, 1200);
}, [pincodeUpdates]);


  // ✅ Function to track only pincode changes
  const handleSpreadsheetChange = useCallback((updatedData) => {
    let newPincodeUpdates = {};
  
    updatedData.forEach((row, index) => {
      let newPincode = row[12]?.value?.trim() || "";
      let oldPincode = spreadsheetData[index]?.[12]?.value?.trim() || "";
  
      if (newPincode !== oldPincode) {
        newPincodeUpdates[index] = newPincode;
      }
    });
  
    // ✅ Avoid unnecessary state updates to prevent infinite re-renders
    if (!_.isEqual(pincodeUpdates, newPincodeUpdates)) {
      setPincodeUpdates(newPincodeUpdates);
    }
  
    setSpreadsheetData((prevData) => {
      if (_.isEqual(prevData, updatedData)) return prevData; // ✅ Prevent redundant updates
      return updatedData;
    });
  
  }, [spreadsheetData, pincodeUpdates]);
  
  

  const addRow = () => {
    setSpreadsheetData((prev) => [
      ...prev,
      ...Array.from({ length: 1 }, createEmptyRow), // Add 10 new rows
    ]);

    setRowLabels((prev) => [
      ...prev,
      ...Array.from({ length: 1 }, (_, i) => `${prev.length + i + 1}`),
    ]);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (!spreadsheetRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = spreadsheetRef.current;

      // If user scrolls near the bottom (100px threshold)
      if (scrollHeight - scrollTop <= clientHeight + 100) {
        addRow(); // Auto-add row
      }
    };

    const spreadsheetDiv = spreadsheetRef.current;
    if (spreadsheetDiv) {
      spreadsheetDiv.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (spreadsheetDiv) {
        spreadsheetDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      addRow();
    }
  };

  // 🔹 Function to convert spreadsheet data into an array of objects
  const formatDataForAPI = () => {
    const commonData = {
      workLocation: batch[0]?.workLocation || "Default Location",
      blockOrBuildingNo: batch[0]?.blockOrBuildingNo || "Default Building",
      shift: batch[0]?.shift || "General Shift",
      department: batch[0].department,
    };

    console.log(commonData?.department, "commonData");

    return spreadsheetData.map((row) => {
      let rowData = {};

      row.forEach((cell, index) => {
        rowData[columnLabels[index]] = cell?.value || ""; // ✅ Ensure `cell` is not undefined
      });

      return {
        employeeId: rowData["Emp. ID"],
        firstName: rowData["First Name"],
        lastName: rowData["Last Name"],
        dob: rowData["D.O.B (DD/MM/YY)"],
        gender: rowData["Gender"],
        emailPersonal: rowData["Email (Personal)"],
        emailOfficial: rowData["Email (Official)"],
        mobileNo: rowData["Mobile No."],
        joiningDate: rowData["D.O.J (DD/MM/YY)"],
        address: rowData["Address"],
        pincode: rowData["Pincode"],
        city: rowData["City"],
        state: rowData["State"],
        country: rowData["Country"],
        idProofNo: rowData["Proof Id No."],
        idProofType: rowData["Proof Id"],
        workLocation: commonData.workLocation,
        blockOrBuildingNo: commonData.blockOrBuildingNo,
        shift: commonData.shift,
        department: commonData?.department,
      };
    });
  };

  const handleUpload = async () => {
    setIsUploading(true);
    setUploadProgress(0);

    const formattedData = formatDataForAPI();

    if (!formattedData || formattedData.length === 0) {
      toast.error("No data to upload.");
      setIsUploading(false);
      return;
    }

    const requiredFields = {
      employeeId: "Emp. ID",
      firstName: "First Name",
      lastName: "Last Name",
      dob: "D.O.B (DD/MM/YY)",
      gender: "Gender",
      emailPersonal: "Email (Personal)",
      emailOfficial: "Email (Official)",
      mobileNo: "Mobile No.",
      joiningDate: "D.O.J (DD/MM/YY)",
      address: "Address",
      pincode: "Pincode",
      city: "City",
      state: "State",
      country: "Country",
      idProofType: "Proof ID",
      idProofNo: "Proof ID No.",
    };

    let errors = [];
    let validEmployees = [];

    formattedData.forEach((row, index) => {
      const isRowEmpty = Object.keys(requiredFields).every((key) => {
        const value = row[key]?.trim();
        return !value || value === "India";
      });

      if (isRowEmpty) return;

      let missingFields = Object.keys(requiredFields).filter(
        (key) => !row[key]?.trim()
      );

      if (missingFields.length > 0) {
        errors.push(
          `Row ${index + 1}: Missing ${missingFields
            .map((key) => requiredFields[key])
            .join(", ")}`
        );
      } else {
        validEmployees.push(row);
      }
    });

    if (errors.length > 0) {
      toast.error(
        <div style={{ textAlign: "left", whiteSpace: "pre-wrap" }}>
          <strong>Some rows have missing fields:</strong>
          <br />
          {errors.join("\n")}
        </div>,
        { autoClose: false }
      );

      setIsUploading(false);
      return;
    }

    if (validEmployees.length === 0) {
      toast.warn("No valid employees to upload.");
      setIsUploading(false);
      return;
    }

    const requestId = uuidv4();
    const customerId = localStorage.getItem("CustomerID");
    const token = localStorage.getItem("token");

    if (!token) {
      toast.error("Authorization token is missing.");
      setIsUploading(false);
      return;
    }

    try {
      let simulatedProgress = 0;
      const progressInterval = setInterval(() => {
        simulatedProgress += 10;
        setUploadProgress(simulatedProgress);
        if (simulatedProgress >= 90) {
          clearInterval(progressInterval);
        }
      }, 500);

      const response = await axios.post(
        `${BaseURL}/employees/bulkRegestration`,
        {
          requestId,
          customerId,
          employeesData: validEmployees,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            if (percentCompleted >= 90) {
              setUploadProgress(90);
            }
          },
        }
      );
      if (response.status === 200 && response.data.success) {
        // toast.success(response.data.message);
        sessionStorage.removeItem("spreadsheetData"); // ✅ Clear sessionStorage
        setSpreadsheetData([]); // ✅ Clear UI
      } else {
        toast.error(
          `Upload failed: ${response.data.message || "Unknown error"}`
        );
      }

      clearInterval(progressInterval);
      setUploadProgress(100);

      if (response.status === 200 && response.data.success) {
        setIsPopupOpen(true);
        const { message, skippedEmployees } = response.data;

        if (skippedEmployees.length > 0) {
          const filteredSkippedEmployees = skippedEmployees.filter(
            (emp) => emp.employeeId || emp.firstName || emp.lastName
          );

          if (filteredSkippedEmployees.length > 0) {
            const skippedList = filteredSkippedEmployees
              .map((emp) => {
                let empIssues = [];

                if (emp.reasons.includes("Duplicate Employee ID")) {
                  empIssues.push(`Duplicate Employee ID: ${emp.employeeId}`);
                }
                if (emp.reasons.includes("Email already exists")) {
                  empIssues.push(
                    `Email Exists: ${emp.emailOfficial || emp.emailPersonal}`
                  );
                }
                emp.reasons.forEach((reason) => {
                  if (
                    reason !== "Duplicate Employee ID" &&
                    reason !== "Email already exists"
                  ) {
                    empIssues.push(reason);
                  }
                });

                return `• ${emp.employeeId || "N/A"} - ${
                  emp.firstName || "N/A"
                } ${emp.lastName || ""}: ${empIssues.join(", ")}`;
              })
              .join("\n");

            toast.warn(
              <div
                style={{
                  maxHeight: "600px",
                  maxWidth: "2000px",
                  overflowY: "auto",
                  padding: "15px",
                  textAlign: "left",
                  wordBreak: "break-word",
                }}
              >
                <strong style={{ display: "block", marginBottom: "10px" }}>
                  Some employees were skipped:
                </strong>
                <pre
                  style={{
                    whiteSpace: "pre-wrap",
                    fontSize: "16px",
                    margin: 0,
                  }}
                >
                  {skippedList}
                </pre>
              </div>,
              {
                autoClose: false,
                closeOnClick: false,
              }
            );
          }
        }

        // toast.success(message);
      } else {
        toast.error(
          `Upload failed: ${response.data.message || "Unknown error"}`
        );
      }
    } catch (error) {
      toast.error(`Error: ${error.response?.data?.message || error.message}`);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <>
      {isUploading && (
        <div style={{ width: "100%", marginTop: "20px", textAlign: "center" }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#ddd",
              borderRadius: "5px",
            }}
          >
            <div
              style={{
                width: `${uploadProgress}%`,
                height: "10px",
                backgroundColor: "#4caf50",
                borderRadius: "5px",
                transition: "width 0.3s ease-in-out",
              }}
            />
          </div>
          <p>{Math.round(uploadProgress)}% Uploaded</p>
        </div>
      )}

      <UploadPopup
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        isUploading={isUploading}
        uploadProgress={uploadProgress}
      />

      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          boxSizing: "border-box",
        }}
      >
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          style={{
            position: "fixed",
            top: "10%",
            left: "50%",
            transform: "translateX(-50%)",
            width: "500px",
            maxWidth: "90vw",
            zIndex: 9999,
            textAlign: "center",
          }}
        />

        {/* ✅ Scrollable Spreadsheet Container */}
        <div
          ref={spreadsheetRef}
          style={{
            flex: 1,
            width: "105%",
            overflowY: "auto",
            maxHeight: "100%", // Reduce height so buttons are always visible
            border: "1px solid #ddd",
            padding: "10px",
          }}
        >
          <Spreadsheet
            data={spreadsheetData}
            onChange={handleSpreadsheetChange}
            columnLabels={columnLabels}
            rowLabels={rowLabels}
            onKeyDown={handleKeyDown}
          />
        </div>

        {/* ✅ Buttons - Always Visible */}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            position: "relative", // Not fixed, so it stays visible
            marginTop: "10px",
            padding: "10px",
            gap: 12,
          }}
        >
          <button
           onClick={() => {
            sessionStorage.removeItem("spreadsheetData"); // ✅ Clear session storage
            navigate("/masters-header"); // ✅ Navigate to another page
          }}
            style={{
              padding: "10px 20px",
              background: "#fff",
              border: "1px solid #000",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Close
          </button>

          <button
            onClick={handleUpload}
            style={{
              padding: "10px 20px",
              background: "#003366",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Upload
          </button>
        </div>
      </div>
    </>
  );
}

export default SpreadsheetComponent;
