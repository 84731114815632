import React from "react";

const EmployeeList = () => {
  return (
    <div className="skeleton-container-employee-list">
      <div className="skeleton-details">
        {Array.from({ length: 15 }).map((_, index) => (
          <div key={index} className="skeleton-detail-employee-list"></div>
        ))}
      </div>
    </div>
  );
};

export default EmployeeList;
