import "./EmployeeMasters.css";
import "../CompanyMasters/Masters.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEmployee } from "../../../context/EmpProvider";

const EmployeeMasters = () => {
  const navigate = useNavigate();
  const { shiftList, department, workLocation, building } = useEmployee();
  const storedData = JSON.parse(localStorage.getItem("formData")) || {
    selectedDepartment: "",
    selectedLocation: "",
    selectedBuilding: "",
    selectedShift: "",
  }
  const [formData, setFormData] = useState(storedData);
  const [inputErrors, setInputErrors] = useState({
    selectedDepartment: false,
    selectedShift: false,
    selectedLocation: false,
    selectedBuilding: false,
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputErrors((prev) => ({
      ...prev,
      [name]: false,
    }));
    setFormData((prev) => {
      const updatedData = {
        ...prev,
        [name]: value,
      };

      localStorage.setItem("formData", JSON.stringify(updatedData));

      return updatedData;
    });
  };
  const openSpreadsheet = () => {
    let errors = {};
  
    if (!formData?.selectedDepartment) {
      errors.selectedDepartment = true;
    }
    if (!formData?.selectedLocation) {
      errors.selectedLocation = true;
    }
    if (!formData?.selectedBuilding) {
      errors.selectedBuilding = true;
    }
    if (!formData?.selectedShift) {
      errors.selectedShift = true;
    }
  
    if (Object.keys(errors).length > 0) {
      setInputErrors(errors);
      return;
    }


    const batch = [
      {
        department: formData.selectedDepartment,
        workLocation: formData.selectedLocation,
        blockOrBuildingNo: formData.selectedBuilding,
        shift: formData.selectedShift,
      }
    ];
  
    navigate("/spreadsheet", { state: { batch } });
  };
  
  return (
    <>
      <div className="Masters_Container">
        <div className="inner_class">
          <div className="text_container">
            <h4>Select Masters for adding Employees</h4>

            <div className="Input_container">
              <div className="Input_container_row">
                <div className="Input_container_child">
                  <select
                    name="selectedDepartment"
                    value={formData.selectedDepartment}
                    onChange={handleInputChange}
                   
                   
                    style={{
                      borderColor: inputErrors.selectedDepartment
                        ? "red"
                        : "#ccc",
                    }}
                  >
                    <option defaultValue={""}></option>
                    {department?.map((department, index) => (
                      <option
                        key={index}
                        value={department?.selectedDepartment}
                      >
                        {department?.department}
                      </option>
                    ))}
                  </select>
                  <div className="input_lebel">Department</div>
                </div>
                <div className="Input_container_child">
                  <select
                    name="selectedLocation"
                   
                    value={formData.selectedLocation}
                    onChange={handleInputChange}
                    style={{
                      borderColor: inputErrors.selectedLocation
                        ? "red"
                        : "#ccc",
                    }}
                  >
                    <option defaultValue={""}></option>
                    {workLocation.map((location, index) => (
                      <option key={index} value={location?.city}>
                        {location?.city}
                      </option>
                    ))}
                  </select>
                  <div className="input_lebel">Location</div>
                </div>
                <div className="Input_container_child">
                  <select
                    name="selectedBuilding"
                    
                    value={formData.selectedBuilding}
                    onChange={handleInputChange}
                    style={{
                      borderColor: inputErrors.selectedBuilding
                        ? "red"
                        : "#ccc",
                    }}
                  >
                    <option defaultValue={""}></option>
                    {building?.map((building_, index) => (
                      <option key={index} value={building_?.buildingno}>
                        {building_?.buildingno}
                      </option>
                    ))}
                  </select>
                  <div className="input_lebel">Building</div>
                </div>
              </div>

              <div className="Input_container_row">
                <div className="Input_container_child">
                  <select
                    name="selectedShift"
                    value={formData.selectedShift}
                    onChange={handleInputChange}
                    
                    style={{
                      borderColor: inputErrors.selectedShift ? "red" : "#ccc",
                    }}
                  >
                    <option defaultValue={""}></option>
                    {shiftList?.map((shift, index) => (
                      <option
                        key={index}
                        value={`${shift.fromtiming}-${shift.totiming}`}
                      >
                        {shift?.fromtiming}-{shift?.totiming}
                      </option>
                    ))}
                  </select>
                  <div className="input_lebel">Shift</div>
                </div>
              </div>
            </div>

            <div className="nextBtn ">
              <button onClick={openSpreadsheet} className="">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeMasters;
