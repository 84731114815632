import React from "react";
import countriesTimezones from "../../utils/countriesTimezones.json";
export const useAttendanceTable = () => {
  const getTimeByCountry = (country, time, state, shiftTiming = null) => {
    let locale = "en-US";
    let timeZone = "UTC";
    const [startTime, endTime] = shiftTiming?.split("-");
    const countryInfo = countriesTimezones[country.toLowerCase()];

    if (countryInfo) {
      locale = countryInfo.locale;

      timeZone = countryInfo.timeZone;

      if (countryInfo.timeZones && state) {
        const stateInfo = countryInfo.timeZones[state.toLowerCase()];
        if (stateInfo) {
          timeZone = stateInfo.timeZone;
        }
      }
    } else {
      console.warn(`No timezone data found for country: ${country}`);
    }

    try {
      const date = new Date(time);
      if (isNaN(date.getTime())) {
        throw new Error("Invalid date");
      }

      const formattedTime = new Intl.DateTimeFormat(locale, {
        timeZone: timeZone,
        timeStyle: "long",
        dateStyle: undefined,
      }).format(date);
      const newTime = formattedTime.replace(/\s+[A-Z]+$/, "").trim();
      console.log(newTime, "New time");

      let newTimePost18 = new Date(newTime);

      const hours = date.getHours();
      const minutes = date.getMinutes();

      const companyHours = parseInt(endTime?.split(":")?.[0], 10);
      const companyMinutes = parseInt(endTime?.split(":")?.[1], 10);
      const companyEndMinutes = companyHours * 60 + companyMinutes;
      const employeeMinutes = hours * 60 + minutes;

      let newTimePost18Conditionally = {};
      if (employeeMinutes >= companyEndMinutes) {
        newTimePost18Conditionally = {
          newTime,
          type: true,
        };
      } else {
        const totalMinutesLate = companyEndMinutes - employeeMinutes;
        const hoursLate = Math.floor(totalMinutesLate / 60);
        const minutesLate = totalMinutesLate % 60;
        newTimePost18Conditionally = {
          newTimeLate: newTime,
          type: false,
          lateMessage: `${hoursLate}hr ${minutesLate}min early leave`,
        };
      }

      const updatedTime = {
        newTime,
        newTimePost18,
        newTimePost18Conditionally,
      };

      console.log(updatedTime, "Updated time");
      return updatedTime;
    } catch (error) {
      console.error("Error formatting time time 1:", `${time}`);
      return "Invalid time or country";
    }
  };

  const getTimeByCountryfForReports = (country, state, time) => {
    let locale = "en-US";
    let timeZone = "UTC";

    const countryInfo = countriesTimezones[country.toLowerCase()];
    if (countryInfo) {
      locale = countryInfo.locale;

      timeZone = countryInfo.timeZone;

      if (countryInfo.timeZones && state) {
        const stateInfo = countryInfo.timeZones[state.toLowerCase()];
        if (stateInfo) {
          timeZone = stateInfo.timeZone;
        }
      }
    } else {
      // console.warn(`No timezone data found for country: ${country}`);
    }

    try {
      const now = new Date();
      const [hours, minutes, seconds] = time.split(":");
      const date = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        hours,
        minutes,
        seconds
      );

      const formattedTime = new Intl.DateTimeFormat(locale, {
        timeZone: timeZone,
        timeStyle: "long",
        dateStyle: undefined,
      }).format(date);

      return formattedTime.replace(/\s+[A-Z]+$/, "").trim();
    } catch (error) {
      return null;
    }
  };

  const usegetTimeByCountryOfLiveTracker = (country, state, time) => {
    let locale = "en-US"; // Default locale
    let timeZone = "UTC"; // Default timezone

    // Normalize the country name to lowercase
    const countryInfo = countriesTimezones[country.toLowerCase()];

    if (countryInfo) {
      locale = countryInfo.locale;

      // Set the timezone based on the country and state
      timeZone = countryInfo.timeZone; // Use the predefined timezone for the country

      // If there are specific state timezones defined, you can incorporate that logic here
      if (countryInfo.timeZones && state) {
        const stateInfo = countryInfo.timeZones[state.toLowerCase()];
        if (stateInfo) {
          timeZone = stateInfo.timeZone; // Override with state timezone if available
        }
      }
    } else {
      console.warn(`No timezone data found for country: ${country}`);
    }

    try {
      // Create a Date object from the UTC time string
      const date = new Date(time); // time is already in ISO 8601 format
      if (isNaN(date.getTime())) {
        throw new Error("Invalid date");
      }

      // Convert the date to the desired timezone
      const formattedTime = new Intl.DateTimeFormat(locale, {
        timeZone: timeZone,
        timeStyle: "long",
        dateStyle: undefined,
      }).format(date);
      console.log(formattedTime, "formatted time");
      return formattedTime.replace(/\s+[A-Z]+$/, "").trim(); // Remove timezone abbreviation
    } catch (error) {
      console.error("Error formatting time 2:", `${time}`);
      return "Invalid time or country";
    }
  };

  return {
    getTimeByCountry,
    getTimeByCountryfForReports,
    usegetTimeByCountryOfLiveTracker,
  };
};
