import React from "react";
import countriesTimezones from "./countriesTimezones.json"; // Adjust the path as necessary

const TimeFormatter = ({ country, state, time }) => {
  const getTimeByCountry = (country, state, time) => {
    let locale = "en-US"; // Default locale
    let timeZone = "UTC"; // Default timezone

    // Normalize the country name to lowercase
    const countryInfo = countriesTimezones[country.toLowerCase()];

    if (countryInfo) {
      locale = countryInfo.locale;

      // Set the timezone based on the country and state
      timeZone = countryInfo.timeZone; // Use the predefined timezone for the country

      // If there are specific state timezones defined, you can incorporate that logic here
      if (countryInfo.timeZones && state) {
        const stateInfo = countryInfo.timeZones[state.toLowerCase()];
        if (stateInfo) {
          timeZone = stateInfo.timeZone; // Override with state timezone if available
        }
      }
    } else {
      console.warn(`No timezone data found for country: ${country}`);
    }

    try {
      // Create a Date object from the UTC time string
      const date = new Date(time); // time is already in ISO 8601 format
      if (isNaN(date.getTime())) {
        throw new Error("Invalid date");
      }

      // Convert the date to the desired timezone
      const formattedTime = new Intl.DateTimeFormat(locale, {
        timeZone: timeZone,
        timeStyle: "long",
        dateStyle: undefined,
      }).format(date);

      return formattedTime.replace(/\s+[A-Z]+$/, "").trim(); // Remove timezone abbreviation
    } catch (error) {
      console.error("Error formatting time:", error);
      return "Invalid time or country";
    }
  };

  const currentTime = getTimeByCountry(country, state, time);

  return <div>{currentTime}</div>;
};

export default TimeFormatter;
