import React, {useState} from "react";
import "./masterHeader.css";

const Topbar = ({ onTabChange = () => {} }) => {
    const [activeTab, setActiveTab] = useState("company-masters");

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        onTabChange(tab);
    };
  
      return (
        <div className="tabbed-header">
          <div>
            <h3>Masters Details</h3>
          </div>
          <div className="tabs">
            <button
              className={activeTab === "company-masters" ? "active" : ""}
              onClick={() =>  handleTabClick("company-masters")}
            >
              Company Masters
            </button>
            <button
              className={activeTab === "employee-masters" ? "active" : ""}
              onClick={() => handleTabClick("employee-masters")}
            >
              Employee Masters
            </button>
          </div>
        </div>
    );
};

export default Topbar;