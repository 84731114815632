import React, { useState } from "react";
import Face from "../../../assets/images/face.svg";
import backGroundImage from "../../../assets/images/from_back.svg";
import facesenseHeading from "../../../assets/images/faceHeading.svg";
import EyeOpen from "../../../assets/images/eyeOpen.svg";
import EyeClose from "../../../assets/images/eyeClose.svg";
import backArrow from "../../../assets/images/backArrow.svg"

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import axios from "axios";
// import { BaseURL } from "../../BaseURL";

const NewPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || {};
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [showPassword1, setShowPassword1] = useState(false);

  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    confirmpassword: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setError(false);
    setMessage("");
  };

  const handleClick = async () => {
    // if (formData.password === formData.confirmpassword) {
    //   const response = await axios.post(
    //     `${BaseURL}/user/changepassword`,
    //     {
    //       email: email,
    //       newpassword: formData.password,
    //     },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   );
    //   if (response.data.status_code === 200) {
    //     setError(false);
    //     setMessage(response.data.message);
    //     setTimeout(() => {
    //       localStorage.setItem("isRegistered", "true");
    //       navigate("/login", {
    //         state: true,
    //       });
    //     }, 3000);
    //   } else {
    //     setError(true);
    //     setMessage(response.data.message);
    //   }
    // } else {
    //   setError(true);
    //   setMessage("Passwords Don't Match");
    // }
  };

  return (
    <>
      <div className="Main_Background">
        <div className="Left_Section">
          <div className="Left_Section_Contents">
            <img src={Face} alt="Facesense" />
            <h1>Your Face is Your ID</h1>
            <p>The new era has arrived !</p>
          </div>
        </div>
        <div className="Right_Section">
          <img src={backGroundImage} className="Form_Background" alt="Back" />
          <div className="Right_Section_Contents">
            <img src={facesenseHeading} alt="Facesense" />
            <div className="Input_Box">
              <h2>Create Password</h2>
              <div className="Input_Field">
                <div className="Label">Password</div>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
                {showPassword ? (
                  <img
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    src={EyeOpen}
                    alt="Eye Button"
                  />
                ) : (
                  <img
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    src={EyeClose}
                    alt="Eye Button"
                  />
                )}
              </div>
              <div className="Input_Field">
                <div className="Label">Confirm Password</div>
                <input
                  type={showPassword1 ? "text" : "password"}
                  name="confirmpassword"
                  value={formData.confirmpassword}
                  onChange={handleInputChange}
                  required
                />
                {showPassword1 ? (
                  <img
                    onClick={() => {
                      setShowPassword1(!showPassword1);
                    }}
                    src={EyeOpen}
                    alt="Eye Button"
                  />
                ) : (
                  <img
                    onClick={() => {
                      setShowPassword1(!showPassword1);
                    }}
                    src={EyeClose}
                    alt="Eye Button"
                  />
                )}
              </div>
            </div>
            <div
              className="Email_Sent"
              style={{ color: error ? "#eb0100" : "#01a601" }}
            >
              {message}
            </div>

            <button type="button" onClick={handleClick}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPassword;
