import React, { useEffect, useState } from "react";
import { useCustomer } from "../../context/CustomerProvider";
import { useEmployee } from "../../context/EmpProvider";
import { useRightSidebar } from "../../hooks/useRightSidebar/useRightSidebar";
import { useModel } from "../../hooks/useModel/useModel";
import { useNavigate } from "react-router-dom";
import EmployeeList from "../../skeleton/employeeDetails/EmployeeList";
import { MdCheckBox } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import Loader from "../../assets/gif/Loader.gif";
import Confirm from "../../model/confirm/Confirm";
import account_icons from "./../../assets/images/account_icons.svg";
import search_icons from "../../assets/images/searchIcons.svg";
import filterIcons from "../../assets/images/Filter list alt.svg";
import employeeImage from "../../assets/images/EmployeeWrapperImage.svg";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
const CommonEmployeeList = ({
  filteredEmployees,
  employee,
  displayedEmployees,
  loading,
  handleScroll,
  searchTerm,
  handleSearchChange,
  openFilter,
  popupFilterBoxRef,
  handleClearAll,
  select,
  handleSelect,
  setCurrent,
  filterConditinally,
  handleSelectCheckBox,
  current,
  applyFilter,
  filterLoading,
  setOpenFilter,
  messageRecaptureForRightSidebar,
  setDisplayedEmployees,
  searchFilter,
  loadMoreEmployees,
  setFilteredEmployees,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [employeeSelectedForRetrend, setEmployeeSelectedForRetrend] = useState(
    []
  );
  const [isEmployeesUpdated, setIsEmployeesUpdated] = useState(false);
  const [selectedLength,setselectedLength] =useState(0);
  const naviagte = useNavigate();
  const { open, handleClose, handleOpen } = useModel();
  const {
    open: open1,
    handleClose: handleClose1,
    handleOpen: handleOpen1,
  } = useModel();
  const message = {
    title: "Sure you need to logout?",
    content: "You need to enter your password while login again.",
    leftBtn: "Logout",
    rightBtn: "Cancel",
    type: "Logout",
  };
  const { customer } = useCustomer();
  const { setCommonRightSidebar } = useEmployee();
  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    });
  }, []);
  useEffect(() => {
    if (isEmployeesUpdated) {
      setCommonRightSidebar(false);
      setIsEmployeesUpdated(false); 
      setselectedLength(0);
    }
  }, [isEmployeesUpdated]);
  const updateEmployees = () => {
    setDisplayedEmployees((prev) =>
      prev.map((employee) => ({
        ...employee,
        isRetrainFrontend: false,
      }))
    );
    setEmployeeSelectedForRetrend([]);
    setIsEmployeesUpdated(true); 
  };
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("modal-overlayHr")) {
      if (customer && customer?.data) {
        updateEmployees();
      }
    }
  };
  const handleMultipleSelect = (id) => {
    const updatedEmployees = displayedEmployees?.map((re) => {
      if (re?._id === id) {
        const isAlreadySelected = employeeSelectedForRetrend?.some(
          (selected) => selected._id === id
        );
  
        const updatedRe = { ...re, isRetrainFrontend: !re?.isRetrainFrontend };
  
        if (isAlreadySelected) {
          setEmployeeSelectedForRetrend((prevState) =>
            prevState.map((selected) =>
              selected._id === id ? { ...selected, isRetrainFrontend: !selected.isRetrainFrontend } : selected
            )
          );
        } else {
          setEmployeeSelectedForRetrend((prevState) => [...prevState, updatedRe]);
        }
  
        return updatedRe;
      } else {
        return re;
      }
    });
  
    setDisplayedEmployees(updatedEmployees);
  };
  const handleSubmit = () => {
    if (messageRecaptureForRightSidebar?.type === "reCapture") {
      if (displayedEmployees.length > 0) {
        const empInfo = displayedEmployees?.find(
          (isRetrainFrontend) => isRetrainFrontend?.isRetrainFrontend
        );
       const selectedCount=displayedEmployees?.filter(
          (isRetrainFrontend) => isRetrainFrontend?.isRetrainFrontend
        );
        if (empInfo) {
          setIsSelected(true);
          setselectedLength(selectedCount?.length)
          handleOpen1();
        } else {
          setIsSelected(false);
          setselectedLength(0)

          handleOpen1();
        }
      }
    }
  };
  const messageReCaptureByModel = {
    title: isSelected ? "Sure to Recapture?" : "No Employees Selected",
    content: isSelected
      ? `Selected ${selectedLength} employee(s) will be moved to your Lite application. Please log in to your FaceSense Lite after clicking on recapture.`
      : "You have not selected any employees. You can proceed without any recapture.",
    leftBtn: "Okay",
    rightBtn: "Cancel",
    type: "WarningSendReCapture",
   
  };
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#fff",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#09355C",
    },
  }));


  return (
    <div
      className={`nominateHr modal-overlayHr ${isOpen ? "open" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className="nominateHrModel">
        <div className="navHr">
          <div
            className="menu_nav subMenu_nav"
            onClick={() => naviagte("/admin-account")}
          >
            <div className="one">
              <span>
                <b>
                  {customer?.data?.businessname
                    ?.split(" ")
                    .slice(0, 2)
                    .join(" ")}
                  {customer?.data?.businessname?.split(" ").length > 2 && "..."}{" "}
                </b>
              </span>
            </div>
            <div className="two">
              <BootstrapTooltip title="Profile">
                <img
                  style={{
                    border: "2px solid  #09355C",
                  }}
                  src={customer?.data?.businesslogo}
                  alt="user"
                  className="logoCompanyNavbar"
                />
              </BootstrapTooltip>
            </div>
          </div>
          <div className="menu_nav ">
            <BootstrapTooltip title="Logout">
              <img
                src={account_icons}
                alt="account"
                onClick={() => {
                  handleOpen();
                }}
              />
            </BootstrapTooltip>
          </div>
        </div>
        <div className="lowerHrSection">
          
            <div className="parentBtnHr">
              <button className="nominateHrBySelect" onClick={handleSubmit}>
                <span>
                Re-Capture
                </span>
              </button>
            </div>
         

          <div className="commonBorderForAllHr">
            {filteredEmployees.length > 0 || employee.length > 0 ? (
              <div className="listOfEmployeeRightHr">
                <div className="All_Employees_List">
                  {(filteredEmployees.length > 0 || employee.length > 0) && (
                    <div
                      className="EmployeeSearch"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <h3 className="totalEmployeeCountOfHrSection">
                        {messageRecaptureForRightSidebar?.heading}
                      </h3>
                      <form
                        className="SearchForm"
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <div className="Search_Input_Data">
                          <div
                            className="searchBox"
                            style={{
                              width: "100%",
                            }}
                          >
                            <input
                              type="text"
                              placeholder="Enter Employee Name"
                              value={searchTerm}
                              onChange={handleSearchChange}
                              style={{
                                "--search-icon": `url(${search_icons})`,
                                width: "100%",
                              }}
                            />
                          </div>
                          <div className="Input_Data_Button">
                            {openFilter && (
                              <div
                                className="model_filter"
                                ref={popupFilterBoxRef}
                              >
                                <div className="heading">
                                  <h3 style={{ cursor: "pointer" }}>
                                    Select List
                                  </h3>
                                  <h3
                                    onClick={handleClearAll}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Clear All
                                  </h3>
                                </div>
                                <div className="filterBasedOn">
                                  <div className="name">
                                    {[
                                      "Department",
                                      "Designation",
                                      "Employmen..",
                                      "Work Location",
                                    ].map((based, i) => (
                                      <div
                                        className={`nameOfThat ${
                                          i === select?.index && "skyBlue"
                                        }`}
                                        key={i}
                                        onClick={() => {
                                          handleSelect(i);
                                          setCurrent(based);
                                        }}
                                      >
                                        <p>{based}</p>
                                      </div>
                                    ))}
                                  </div>
                                  <div className="borderCenter"></div>
                                  <div className="nameOfChoosen">
                                    {filterConditinally?.map((v, i) => (
                                      <div
                                        className="checkbox_container"
                                        key={i}
                                      >
                                        <div
                                          className="iconsCheckBox"
                                          onClick={() =>
                                            handleSelectCheckBox(v)
                                          }
                                        >
                                          {v?.isTick ? (
                                            <MdCheckBox fontSize={22} />
                                          ) : (
                                            <MdCheckBoxOutlineBlank
                                              fontSize={22}
                                            />
                                          )}
                                        </div>

                                        <div className="nameDesig">
                                          {current === "Department" && (
                                            <p>{v?.department}</p>
                                          )}
                                          {current === "Designation" && (
                                            <p>{v?.designation}</p>
                                          )}
                                          {current === "Employmen.." && (
                                            <p>{v?.employmenttype}</p>
                                          )}

                                          {current === "Work Location" && (
                                            <p>{v?.city}</p>
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <div className="filterBtn">
                                  <button onClick={applyFilter}>
                                    {filterLoading ? (
                                      <span>Filtering</span>
                                    ) : (
                                      <span>Apply Filter</span>
                                    )}
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className="Search_Filter_Data"
                          onClick={() => setOpenFilter((prev) => !prev)}
                        >
                          <img src={filterIcons} alt="Filter" />
                        </div>
                      </form>
                    </div>
                  )}

                  <div className="Employees_ListsHr" onScroll={handleScroll}>
                    {displayedEmployees.length > 0 ? (
                      displayedEmployees.map((data, i) => (
                        <div
                          key={i}
                          className="Employee_List_Data"
                          onClick={() => {
                            if (!data?.isReTrainStrict) {
                              handleMultipleSelect(data?._id);
                            }
                          }}
                          tabIndex="0"
                          style={{
                            padding: "10px",
                            filter: data?.isReTrainStrict
                              ? "blur(1px)"
                              : "none",
                            pointerEvents: data?.isReTrainStrict
                              ? "none"
                              : "auto",
                            opacity: data?.isReTrainStrict ? 0.9 : 1,
                          }}
                        >
                          <div className="Employee_Picture">
                            <img
                              src={
                                data?.profilePhoto
                                  ? !data.isReTrainStrict
                                    ? data?.profilePhoto
                                    : employeeImage
                                  : employeeImage
                              }
                              alt={`${data?.firstName} ${data?.lastName}'s picture`}
                            />
                          </div>
                          <div className="Employee_Content">
                            <h1 style={{ margin: "0 0 7px 0" }}>
                              {data?.firstName} {data?.lastName}
                            </h1>
                            <p style={{ margin: "0 0 7px 0" }}>
                              <strong style={{ fontWeight: "bold" }}>
                                {data?.designation}
                              </strong>
                            </p>
                            <p style={{ margin: "0 0 7px 0" }}>
                              {data?.emailOfficial}
                            </p>
                            <p style={{ margin: "0" }}>{data?.mobileNo}</p>
                          </div>
                          <div className="status_employee">
                            {data?.isReTrainStrict ? (
                              <MdCheckBox fontSize={25} />
                            ) : data?.isRetrainFrontend ? (
                              <MdCheckBox fontSize={25} />
                            ) : (
                              <MdCheckBoxOutlineBlank fontSize={25} />
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div style={{ padding: "10px", textAlign: "center" }}>
                        No employee found
                      </div>
                    )}
                    {loading && !searchFilter && (
                      <div style={{ padding: "10px", textAlign: "center" }}>
                        <img src={Loader} alt="loader" height={50} width={50} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <EmployeeList />
            )}
          </div>
        </div>
      </div>
      <Confirm open={open} handleClose={handleClose} message={message} />

      <Confirm
        open={open1}
        handleClose={handleClose1}
        message={messageReCaptureByModel}
        commonList={displayedEmployees}
        employeeSelectedForRetrend={employeeSelectedForRetrend}
        setDisplayedEmployees={setDisplayedEmployees}
        displayedEmployees={displayedEmployees}
        searchFilter={searchFilter}
        setEmployeeSelectedForRetrend={setEmployeeSelectedForRetrend}
        setIsSelected={setIsSelected}
        setselectedLength={setselectedLength}
        filteredEmployees={filteredEmployees}
        loadMoreEmployees={loadMoreEmployees}
        setFilteredEmployees={setFilteredEmployees}
      />
    </div>
  );
};

export default CommonEmployeeList;
